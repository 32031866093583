<template>
  <price-modal-form
    :price="selectedPrice"
    modal-id="priceModalId"
    :payment-id="paymentId"
    :payment-name="paymentName"
    @submit="submitPrice"
  ></price-modal-form>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col">
          <h6>Prices</h6>
        </div>
        <div class="col">
          <button
            class="btn btn-primary float-right margin-0 pd-10"
            data-bs-toggle="modal"
            data-bs-target="#priceModalId"
          >
            <span><i class="fas fa-plus-circle"></i></span>
            Add
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <EasyDataTable
          :headers="headers"
          :server-items-length="prices.length"
          :items="prices"
        >
          <template #item-view="item">
            <button
              class="btn btn-primary my-3"
              data-bs-toggle="modal"
              data-bs-target="#priceModalId"
              @click="viewPrice(item)"
            >
              View
            </button>
          </template>
        </EasyDataTable>
      </div>
    </div>
  </div>
</template>
<script>
import PriceModalForm from "./PriceModalForm.vue";
// import useValidate from '@vuelidate/core'
// import {required,numeric,minLength} from '@vuelidate/validators'
// import RequestService from "@/services/request.service";
// import e2payApis from "@/services/e2pay-apis";

export default {
  name: "PriceTable",
  props: {
    paymentId: Number,
    paymentName: String,
    item: undefined,
  },
  components: {
    PriceModalForm,
  },
  mounted() {
    // this.fetchData();
    this.prices = this.item;
  },
  emits: ["addItem"],
  data: () => ({
    headers: [
      { text: "Partner Code", value: "form_p_partner", sortable: true },
      { text: "Partner Name", value: "form_p_partner_name", sortable: true },
      { text: "Min Amount", value: "form_minamount", sortable: true },
      // { text: "FFee", value: "toppay.ffee" , sortable: true},
      // { text: "FModel", value: "toppay.fmodel_text" , sortable: true},
      // { text: "SFee", value: "toppay.sfee" , sortable: true},
      // { text: "SModel", value: "toppay.smodel_text" , sortable: true},
      // { text: "ResellerFFee", value: "referral.ffee" , sortable: true},
      // { text: "ResellerFModel", value: "referral.fmodel_text" , sortable: true},
      // { text: "ResellerSFee", value: "referral.sfee" , sortable: true},
      // { text: "ResellerSModel", value: "referral.smodel_text" , sortable: true},
      // { text: "SupplierFFee", value: "supplier.ffee" , sortable: true},
      // { text: "SupplierFModel", value: "supplier.fmodel_text" , sortable: true},
      // { text: "SupplierSFee", value: "supplier.sfee" , sortable: true},
      // { text: "SupplierSModel", value: "supplier.smodel_text" , sortable: true},
      { text: "Active", value: "form_p_active", sortable: true },
      { text: "View", value: "view", sortable: true },
    ],
    prices: [],
    selectedPrice: {},
  }),
  methods: {
    submitPrice(price) {
      const foundIndex = this.prices.findIndex(
        (item) => item.form_p_partner == price.form_p_partner
      );
      if (foundIndex > -1) {
        this.prices[foundIndex] = price;
      } else {
        this.prices.push(price);
      }
      this.$emit("addItem", this.prices);
    },
    viewPrice(price) {
      this.selectedPrice = price;
    },
  },
  watch: {
    item: function (newVal) {
      this.prices = newVal;
    },
  },
};
</script>
