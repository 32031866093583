<template>
  <main class="main-content">
    <NavigationTop
      :pages="currentRouteName"
      :subpages="currentRouteName"
    ></NavigationTop>
    <div class="container-fluid py-4">
      <ModalSendCallback
        :openDialog="isOpenSendCallbackDialog"
        @close-dialog="closeDialog"
        :data="dataSendCallback"
        :loading="loadingSendCallback"
      ></ModalSendCallback>
      <ModalCheckStatusNextransTrxPayout
        :openDialog="isOpenCheckStatus"
        @close-dialog="closeDialog"
        :message="checkStatusMessage"
        :loading="loadingCheckStatus"
      ></ModalCheckStatusNextransTrxPayout>
      <ModalPayoutDetailsTrx
        :openDialog="isOpenDetailTrxDialog"
        @close-dialog="closeDialog"
        :data="dataDetailsTrx"
        :loading="loadingSeeDetails"
      ></ModalPayoutDetailsTrx>
      <div class="row">
        <div class="col-12">
          <ToppayNotification :menu="'payout'"></ToppayNotification>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card mb-4">
            <div class="card-header pb-0">
              <h6>Data {{ currentRouteName }}</h6>
            </div>
            <div class="card">
              <!-- <v-expand-transition> 
                <div class="card-body p-3" v-show="expand">
                  <TransactionChart :loading="loading" :chartdata=chartData chartTitle="Payout Trx"></TransactionChart>
                </div>
              </v-expand-transition> -->
              <div class="card-body p-3">
                <TableFilter
                  f_action="payout"
                  :f_loading="loading"
                  :f_submit="searchSubmit"
                  :f_download="true"
                  :f_partner_code="true"
                  :f_amount="true"
                  :f_date_range="true"
                  :f_refno="true"
                  :f_sourcerefno="true"
                  :f_destination="true"
                  :f_status="true"
                  :f_channel="true"
                  :can_download="true"
                  :can_show_chart="true"
                  @toggle-expand="toggleExpandListener"
                  :expand="expand"
                  :f_submitdownload="downloadSubmit"
                  :holidayInfo="false"
                ></TableFilter>
              </div>
              <div class="card-body p-3">
                <div class="row">
                  <ShapeInfo
                    :customCols="'col'"
                    :loading="loading"
                    title="Daily Topup Amount"
                    :value="totalTopupAmount"
                    :imgsrc="require('@/assets/img/idr-icon.png')"
                  ></ShapeInfo>
                  <ShapeInfo
                    :customCols="'col'"
                    :loading="loading"
                    :isReadyToSettle="true"
                    title="Remaining Balance"
                    :value="currentBalance"
                    :imgsrc="require('@/assets/img/idr-icon.png')"
                  ></ShapeInfo>
                  <ShapeInfo
                    :customCols="'col'"
                    :loading="loading"
                    title="Accumulated Topup Amount"
                    :value="accumulatedTopupAmount"
                    :subvalue="subvalue"
                    :imgsrc="require('@/assets/img/idr-icon.png')"
                  ></ShapeInfo>
                </div>
                <div class="row mt-4">
                  <a
                    :href="filedownload"
                    v-if="filedownload"
                    class="btn btn-behance mr-2"
                    type="button"
                    ><i class="fa fa-solid fa-file-excel"></i> Dowload</a
                  >
                  <EasyDataTable
                    show-index
                    buttons-pagination
                    alternating
                    v-model:server-options="serverOptions"
                    :server-items-length="numberOfPages"
                    :loading="loading"
                    :headers="filteredHeaders"
                    :items="payouttransactions"
                    :search-field="searchField"
                    :search-value="searchValue"
                  >
                    <template #expand="item">
                      <div style="padding: 15px">
                        {{ item.product_name }} settlement
                        {{ item.settlement_time }} days
                      </div>
                    </template>
                    <template #item-trx_status="item">
                      <span
                        v-if="item.trx_status == '00'"
                        class="badge bg-success text-white text-bold"
                        >{{ item.trx_status }}</span
                      >
                      <span
                        v-else-if="item.trx_status == '96'"
                        class="badge bg-error text-white text-bold"
                        >{{ item.trx_status }}</span
                      >
                      <span
                        v-else
                        class="badge bg-warning text-white text-bold"
                        >{{ item.trx_status }}</span
                      >
                    </template>
                    <template #item-sts_desc="item">
                      <span
                        v-if="item.sts_desc == 'SUCCESS'"
                        class="badge bg-success text-white text-bold"
                        >{{ item.sts_desc }}</span
                      >
                      <span
                        v-else-if="item.sts_desc == 'FAILED'"
                        class="badge bg-error text-white text-bold"
                        >{{ item.sts_desc }}</span
                      >
                      <span
                        v-else
                        class="badge bg-warning text-white text-bold"
                        >{{ item.sts_desc }}</span
                      >
                    </template>
                    <template #item-amount="{ amount }">
                      <span class="text-bold">{{ amount }}</span>
                    </template>
                    <template #item-actions="item">
                      <div
                        class="d-flex align-items-center justify-content-evenly"
                        style="column-gap: 8px"
                      >
                        <div class="tooltip-wrapper">
                          <button
                            class="btn btn-light my-3 d-flex align-items-center justify-content-center p-2"
                            @click="seeDetailTrx(item)"
                          >
                            <i class="fa-solid fa-eye" aria-hidden="true"></i>
                          </button>
                          <span class="tooltip-text">See Detail</span>
                        </div>
                        <div class="tooltip-wrapper">
                          <button
                            class="btn btn-primary my-3 d-flex align-items-center justify-content-center p-2"
                            @click="
                              sendCallback({
                                source_refno: item.source_refno,
                                partner_code: item.partner_code,
                              })
                            "
                          >
                            <i
                              class="fa-solid fa-paper-plane"
                              aria-hidden="true"
                            ></i>
                          </button>
                          <span class="tooltip-text">Send Callback</span>
                        </div>
                        <div
                          class="tooltip-wrapper"
                          v-if="
                            item.sts == '97' || item.sts_desc == 'PROCESSING'
                          "
                        >
                          <button
                            class="btn btn-warning my-3 d-flex align-items-center justify-content-center p-2"
                            @click="
                              checkStatusNextransTrx({
                                pg_trxid: item.pg_trxid,
                                refNo: item.refNo,
                              })
                            "
                          >
                            <i class="fa-solid fa-arrows-rotate"></i>
                          </button>
                          <span class="tooltip-text">Check Status</span>
                        </div>
                      </div>
                    </template>
                    <template #loading>
                      <img
                        :src="imageLoading"
                        style="width: 100px; height: 80px"
                      />
                    </template>
                  </EasyDataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import RequestService from "@/services/request.service";
import NavigationTop from "@/components/NavigationTop";
import TableFilter from "@/views/TableFilter";
// import TransactionChart from  "@/components/TransactionChart";
import e2payApis from "@/services/e2pay-apis";
import ShapeInfo from "@/components/ShapeInfo";
import ToppayNotification from "@/components/ToppayNotification";
import ModalSendCallback from "@/views/ModalSendCallback";
import ModalCheckStatusNextransTrxPayout from "@/views/payout/ModalCheckStatusNextransTrxPayout";
import ModalPayoutDetailsTrx from "@/views/payout/ModalPayoutDetailsTrx";
import dashboardService from "@/services/dashboard.service";

export default {
  name: "TablePayout",
  props: {
    model: String,
  },
  components: {
    NavigationTop,
    TableFilter,
    ShapeInfo,
    ToppayNotification,
    ModalSendCallback,
    ModalCheckStatusNextransTrxPayout,
    ModalPayoutDetailsTrx,
    // TransactionChart
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    filteredHeaders() {
      return this.headers.filter((header) => {
        if (header.value === "pg_id_desc" && this.roleId != 0) {
          return false;
        }
        return true;
      });
    },
  },
  methods: {
    toggleExpandListener() {
      this.expand = !this.expand;
    },
    downloadSubmit(downloadtype, dataParam) {
      this.loading = true;
      this.searchParam = dataParam;
      this.searchParam.download_type = downloadtype;
      this.searchParam.download = true;
      this.searchParam.trxType = "payout";
      this.searchParam.serverOptions = this.serverOptions;
      RequestService.postRequest(
        e2payApis.GET_LINK_DOWNLOAD,
        this.searchParam
      ).then((response) => {
        this.loading = false;
        window.open(response.data.data.downloadLink, "_blank");
      });
    },
    searchSubmit(dataParam) {
      this.searchParam = dataParam;
      this.searchParam.trxType = "payout";
      this.searchParam.serverOptions = this.serverOptions;
      this.fetchData();
    },
    fetchData() {
      this.loading = true;
      this.searchParam.trxType = "payout";
      this.searchParam.chartType = "payout";
      this.searchParam.serverOptions = this.serverOptions;
      RequestService.postRequest(e2payApis.GET_TRX, this.searchParam).then(
        (response) => {
          this.payouttransactions = response.data.data.data;
          this.numberOfPages = response.data.data.dataCount;
          this.loading = false;
          this.filedownload = response.data.data.downloadLink || "";
          // if(!this.filedownload){
          //   this.downloadSubmit();
          // }
        }
      );
      RequestService.postRequest(
        e2payApis.GET_PAYOUT_CHART_DATA,
        this.searchParam
      ).then((response) => {
        this.chartData = response.data.data.chart1;
        this.loading = false;
      });
      RequestService.postRequest(
        e2payApis.GET_BALANCE_HISTORY,
        this.searchParam
      ).then((response) => {
        this.totalTopupAmount = response.data.data.totalTopupAmount;
        this.accumulatedTopupAmount = response.data.data.accumulatedTopupAmount;
        this.currentBalance = response.data.data.currentBalance;
        this.loading = false;
      });
    },
    seeDetailTrx(payload) {
      this.isOpenDetailTrxDialog = true;
      if (this.isOpenDetailTrxDialog) {
        this.loadingSeeDetails = true;

        setTimeout(() => {
          this.loadingSeeDetails = false;
          this.dataDetailsTrx = {
            ...payload,
            roleId: this.roleId,
            roleName: this.roleName,
          };
        }, 2000);
      }
    },
    sendCallback(payload) {
      this.isOpenSendCallbackDialog = true;
      if (this.isOpenSendCallbackDialog) {
        this.loadingSendCallback = true;
        RequestService.postRequest(
          `${e2payApis.SEND_CALLBACK}${
            payload?.source_refno ? `/${payload?.source_refno}` : ""
          }${payload?.partner_code ? `/${payload?.partner_code}` : ""}`
        )
          .then((response) => {
            this.dataSendCallback = response.data.data;
          })
          .finally(() => {
            this.loadingSendCallback = false;
          });
      }
    },
    checkStatusNextransTrx(payload) {
      this.isOpenCheckStatus = true;
      if (this.isOpenCheckStatus) {
        this.loadingCheckStatus = true;
        RequestService.postRequest(e2payApis.CHECK_STATUS_NEXTRANS_TRX, {
          pgTrxId: payload.pg_trxid,
          refNo: payload.refNo,
        })
          .then(() => {
            this.checkStatusMessage =
              "Transaction status has been checked successfully!";

            this.fetchData();
          })
          .finally(() => {
            this.loadingCheckStatus = false;
          });
      }
    },
    closeDialog() {
      this.isOpenSendCallbackDialog = false;
      this.isOpenCheckStatus = false;
      this.isOpenDetailTrxDialog = false;
    },
  },
  data: () => ({
    roleId: dashboardService.getUserRoleId(),
    roleName: dashboardService.getUserRole(),
    checkStatusMessage: "",
    dataSendCallback: [],
    dataDetailsTrx: {},
    isOpenDetailTrxDialog: false,
    isOpenSendCallbackDialog: false,
    isOpenCheckStatus: false,
    filedownload: "",
    imageLoading: require("@/assets/img/cube_loading.gif"),
    numberOfPages: 0,
    payouttransactions: [],
    content: "",
    searchField: undefined,
    searchValue: "",
    loading: false,
    loadingCheckStatus: false,
    loadingSendCallback: false,
    loadingSeeDetails: false,
    totalTopupAmount: "0",
    currentBalance: "0",
    accumulatedTopupAmount: "0",
    subvalue: "Until MM-YYYY",
    headers: [
      { text: "Transaction Date", value: "trx_datetime" },
      { text: "Transaction Ref No.", value: "refNo" },
      { text: "Merchant Ref No.", value: "source_refno" },
      { text: "Merchant Name", value: "partner_name" },
      { text: "Status", value: "sts_desc", sortable: true },
      { text: "Beneficiary No.", value: "customerId" },
      { text: "Beneficiary Name", value: "customer_name" },
      { text: "Channel", value: "product_name" },
      { text: "Issuer", value: "pg_id_desc" },
      { text: "Transaction Amount", value: "amount" },
      { text: "Fee Amount", value: "feeAmount" },
      { text: "Deduction Amount", value: "netamount" },
      // { text: "RC", value: "trx_status" },
      // { text: "Merchant Code", value: "partner_code" },
      // { text: "RefNo", value: "refNo" },
      // { text: "SourceRefNo", value: "source_refno" },
      // { text: "Destination", value: "customerId" },
      // { text: "Type", value: "service_name" },
      // { text: "Gross Amount (Rp.)", value: "netamount" },
      // { text: "Desc", value: "product_name" },
      { text: "Action", value: "actions", sortable: false },
    ],
    searchParam: {},
    serverOptions: {
      page: 1,
      rowsPerPage: 25,
      sortBy: "",
      sortType: "",
    },
    expand: false,
    chartData: {
      labels: [
        "2023-08-01",
        "2023-08-09",
        "2023-08-10",
        "2023-08-19",
        "2023-08-21",
      ],
      datasets: [
        {
          label: "Payout",
          backgroundColor: "#a3f879",
          borderColor: "#a3f879",
          data: [0, 1, 3, 0, 0],
          tension: 0.1,
        },
      ],
    },
  }),
  mounted() {
    this.fetchData();
    const today = new Date();
    this.subvalue =
      "Until " +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      today.getFullYear();
  },
  watch: {
    serverOptions: {
      handler() {
        this.searchParam.serverOptions = this.serverOptions;
        this.fetchData(this.searchParam);
      },
      deep: true,
    },
    searchParam: function (newVal) {
      const today = new Date(newVal.filter_date_range_end);
      this.subvalue =
        "Until " +
        ("0" + (today.getMonth() + 1)).slice(-2) +
        "-" +
        today.getFullYear();
    },
  },
};
</script>

<style scoped>
.tooltip-wrapper {
  position: relative;
  display: inline-block;
}

.tooltip-wrapper .tooltip-text {
  visibility: hidden;
  width: 120px;
  background-color: #f4f4f4;
  color: #212121;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 10;
  top: -20px;
  left: 50%;
  /* right: 50%; */
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-wrapper .tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #f4f4f4 transparent transparent transparent;
}

.tooltip-wrapper:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
</style>
