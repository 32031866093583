<template>
  <div class="container-fluid py-4">
    <ModalManualDisbursement
      :openDialog="isOpenModalDetail"
      @close-dialog="closeDialog"
      :data="manualDisbursementDetail"
      :loading="isLoadingDetail"
    ></ModalManualDisbursement>
    <div class="row">
      <div class="col-12">
        <div class="card mb-12">
          <div class="card-header pb-0">
            <div class="row">
              <div class="col">
                <h6>Data {{ currentRouteName }}</h6>
              </div>
              <div class="col">
                <div
                  class="d-flex align-items-center justify-content-end"
                  style="gap: 8px"
                >
                  <router-link
                    to="/bulk-disbursement"
                    class="btn btn-primary float-right pd-10"
                  >
                    <span><i class="fas fa-plus-circle"></i></span>
                    Create Bulk Disbursement
                  </router-link>
                  <router-link
                    to="/single-disbursement"
                    class="btn btn-primary float-right pd-10"
                  >
                    <span><i class="fas fa-plus-circle"></i></span>
                    Create Single Disbursement
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body p-3">
              <TableFilter
                :f_submit="searchSubmit"
                :f_loading="isLoadingList"
                :f_disburse_type="true"
                :f_disburse_status="true"
              ></TableFilter>
            </div>
            <div class="card-body p-3">
              <EasyDataTable
                show-index
                buttons-pagination
                alternating
                v-model:server-options="serverOptions"
                :server-items-length="numberOfPages"
                :headers="headers"
                :items="manualDisbursementList"
                :loading="isLoadingList"
              >
                <template #item-disburse_status_desc="item">
                  <span
                    v-if="item.disburse_status_desc == 'ON PROCESS'"
                    class="badge bg-info text-white text-bold"
                    >{{ item.disburse_status_desc }}</span
                  >
                  <span
                    v-if="item.disburse_status_desc == 'ALL SUCCESS'"
                    class="badge bg-success text-white text-bold"
                    >{{ item.disburse_status_desc }}</span
                  >
                  <span
                    v-if="item.disburse_status_desc == 'ALL FAILED'"
                    class="badge bg-error text-white text-bold"
                    >{{ item.disburse_status_desc }}</span
                  >
                  <span
                    v-if="item.disburse_status_desc == 'FAILED'"
                    class="badge bg-error text-white text-bold"
                    >{{ item.disburse_status_desc }}</span
                  >
                  <span
                    v-if="item.disburse_status_desc == 'PARTIAL SUCCESS'"
                    class="badge bg-warning text-white text-bold"
                    >{{ item.disburse_status_desc }}</span
                  >
                  <span
                    v-if="item.disburse_status_desc == 'PARTIAL FAILED'"
                    class="badge bg-warning text-white text-bold"
                    >{{ item.disburse_status_desc }}</span
                  >
                </template>
                <template #item-actions="item">
                  <div
                    class="d-flex align-items-center justify-content-start"
                    style="column-gap: 8px"
                  >
                    <div class="tooltip-wrapper">
                      <button
                        class="btn btn-light my-3 d-flex align-items-center justify-content-center p-2"
                        @click="seeManualDisbursementDetail(item)"
                      >
                        <i class="fa-solid fa-eye" aria-hidden="true"></i>
                      </button>
                      <span class="tooltip-text">See Detail</span>
                    </div>
                  </div>
                </template>
              </EasyDataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardService from "@/services/dashboard.service";
import TableFilter from "@/views/TableFilter";
import ModalManualDisbursement from "./ModalManualDisbursement";

export default {
  name: "ManualDisbursementList",
  components: {
    TableFilter,
    ModalManualDisbursement,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    searchSubmit(dataParam) {
      this.searchParam = {
        ...dataParam,
        filter_disburse_type: dataParam?.filter_disburse_type?.id || "",
        filter_disburse_status: dataParam?.filter_disburse_status?.id || "",
      };
      this.searchParam.serverOptions = this.serverOptions;
      this.fetchData();
    },
    fetchData() {
      this.isLoadingList = true;
      this.searchParam.serverOptions = this.serverOptions;
      DashboardService.getDisbursementSummaries(this.searchParam)
        .then((response) => {
          this.manualDisbursementList = response.data.data;
          this.numberOfPages = response.data.data.dataCount;
        })
        .finally(() => {
          this.isLoadingList = false;
        });
    },
    seeManualDisbursementDetail(data) {
      this.isOpenModalDetail = true;
      this.isLoadingDetail = true;
      DashboardService.getDisbursementDetails({
        idSummary: data?.disburse_summary_id,
      })
        .then((response) => {
          this.manualDisbursementDetail = response.data.data;
        })
        .finally(() => {
          this.isLoadingDetail = false;
        });
    },
    closeDialog() {
      this.isOpenModalDetail = false;
    },
  },
  data: () => ({
    imageLoading: require("@/assets/img/cube_loading.gif"),
    isOpenModalDetail: false,
    manualDisbursementList: [],
    manualDisbursementDetail: {},
    numberOfPages: 0,
    isLoadingList: false,
    isLoadingDetail: false,
    headers: [
      { text: "Disburse Type", value: "disburse_type" },
      { text: "Disburse Status", value: "disburse_status_desc" },
      { text: "Requested At", value: "requested_at" },
      { text: "Total Amount (IDR)", value: "total_amount" },
      { text: "Success Amount (IDR)", value: "success_amount" },
      { text: "Failed Amount (IDR)", value: "failed_amount" },
      { text: "Remarks", value: "remarks" },
      { text: "Action", value: "actions" },
    ],
    searchParam: {},
    serverOptions: {
      page: 1,
      rowsPerPage: 25,
      sortBy: "",
      sortType: "",
    },
  }),
  mounted() {
    this.fetchData();
  },
  watch: {
    serverOptions: {
      handler() {
        this.searchParam.serverOptions = this.serverOptions;
        this.fetchData(this.searchParam);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.tooltip-wrapper {
  position: relative;
  display: inline-block;
}

.tooltip-wrapper .tooltip-text {
  visibility: hidden;
  width: 120px;
  background-color: #f4f4f4;
  color: #212121;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 10;
  top: -20px;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-wrapper .tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #f4f4f4 transparent transparent transparent;
}

.tooltip-wrapper:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
</style>
