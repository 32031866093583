<template>
  <main class="main-content">
    <div class="container">
      <div class="center">
        <div class="card mt-12">
          <div class="card-body text-center">
            <div
              style="
                border-radius: 200px;
                height: 200px;
                width: 200px;
                background: #f8faf5;
                margin: 0 auto;
              "
            >
              <i class="checkmark" v-if="isSuccess">✓</i>
              <i class="crossmark" v-else>X</i>
            </div>
            <h1 :class="isSuccess ? 'success-title' : 'failed-title'">
              {{ isSuccess ? "Success" : "Failed" }}
            </h1>
            <p class="subtitle">{{ getMessage }}</p>
            <p class="subtitle" v-if="!isSuccess">Please try again</p>
            <p>
              <i
                >You'll be redirected to merchant site shortly in
                {{ remainingTime }}</i
              >
            </p>
            <p class="text-danger"><strong>DO NOT CLOSE THIS WINDOW</strong></p>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "PaymentSuccessPage",
  created() {
    document.title = "Payment Success";
    const link = document.createElement("link");
    link.rel = "icon";
    link.href = "/favicon-co.ico"; // Update with the actual path to your custom favicon
    document.head.appendChild(link);
  },
  mounted() {
    if (this.$route.query.redirectUrl == undefined) {
      this.$router.go(-1);
    } else {
      this.isSuccess = this.$route.query.isSuccess == 1;
      this.status = this.$route.query.status;
      this.timer = setInterval(() => {
        if (this.remainingTime > 0) {
          this.remainingTime--;
        } else {
          clearInterval(this.timer);
          window.location.replace(this.$route.query.redirectUrl);
        }
      }, 1000);
    }
  },
  components: {},
  computed: {
    getMessage: function () {
      let msg = "";
      if (this.isSuccess) {
        if (this.status == 2) {
          msg = "This transaction already paid";
        } else {
          msg = "Payment success";
        }
      } else {
        msg = "failed";
      }
      return msg;
    },
  },
  methods: {},
  data: () => ({
    isSuccess: false,
    status: 1,
    loading: false,
    timer: null,
    remainingTime: 5,
  }),
};
</script>

<style scoped>
.checkmark {
  color: #9abc66;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}
.crossmark {
  color: #ef1616;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}
.subtitle {
  color: #404f5e;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  margin: 0;
}
.success-title {
  color: #88b04b;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}
.failed-title {
  color: #ef1616;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}
</style>
