<template>
  <DashboardLeftMenu />
  <main class="main-content">
    <NavigationTop
      :pages="currentRouteName"
      :subpages="currentRouteName"
    ></NavigationTop>
    <SingleDisbursementForm />
  </main>
</template>

<script>
import DashboardLeftMenu from "@/components/DashboardLeftMenu";
import NavigationTop from "@/components/NavigationTop";
import SingleDisbursementForm from "./SingleDisbursementForm";

export default {
  name: "SingleDisbursementIndex",
  components: {
    DashboardLeftMenu,
    NavigationTop,
    SingleDisbursementForm,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<style scoped></style>
