<template>
  <v-dialog persistent v-model="dialog" height="auto">
    <div class="card card-frame">
      <div v-if="loading" style="height: 182px">
        <div
          class="text-center d-flex align-items-center justify-content-center h-100"
        >
          <img
            width="10"
            class="img-fluid"
            :src="imageLoading"
            style="width: 80px; height: 50px"
          />
        </div>
      </div>
      <div v-else>
        <div class="card-header">
          <span class="text-h5" v-for="(cb, i) in data" :key="i">
            {{ cb }}
          </span>
        </div>
        <div class="card-body text-center pt-0">
          <div class="row mt-2">
            <div class="col-12 col-md-12">
              <button
                @click="this.$emit('closeDialog')"
                class="btn btn-block btn-info mb-0 mr-0"
                :disabled="loading"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalSendCallback",
  props: {
    modalId: String,
    openDialog: Boolean,
    data: Array,
    loading: Boolean,
  },
  data: () => ({
    imageLoading: require("@/assets/img/cube_loading.gif"),
    dialog: false,
  }),
  emits: ["closeDialog"],
  watch: {
    openDialog: function (newVal) {
      this.dialog = newVal;
    },
    data: function () {},
  },
};
</script>
