<template>
  <div class="card card-frame">
    <div class="card-header">
      <h6 class="font-weight-bolder mb-0">
        Simulator Payin Page 3: Payment Instruction
      </h6>
    </div>
    <div class="card-body">
      <div class="row" v-if="isError">
        <div class="col">
          <div
            class="alert alert-danger alert-dismissible fade show"
            role="alert"
          >
            <span class="alert-text text-white"
              ><strong>Error!</strong> {{ errorMsg }}</span
            >
            <button
              class="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>
      <form>
        <div class="row">
          <div class="col-12">
            <h6 class="">Please complete the payment before the time end.</h6>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <div class="mb-3 row">
              <div class="col-4 text-right"><h2>Timer:</h2></div>
              <div class="col-7 timer">
                <h2 class="text-red">{{ countDown }}</h2>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-12">
            <p>
              This is a simulation payment instruction for showing how to
              process payment based on chosen payment method.
            </p>
            <p>
              Please choose Confirm Payment Button if you want to complete
              payment process.
            </p>
            <p>
              Please choose Cancel Payment Button if you want to cancel payment
              process.
            </p>
          </div>
        </div>
      </form>
      <div class="row mt-2">
        <div class="col-12 col-md-12">
          <button
            @click="cancel()"
            class="btn btn-danger mr-2"
            :disabled="loading"
          >
            Cancel Payment
          </button>
          <button
            @click="confirm()"
            class="btn btn-success mr-2"
            :disabled="loading"
          >
            Confirm Payment
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import useValidate from "@vuelidate/core";
import RequestService from "@/services/request.service";
import e2payApis from "@/services/e2pay-apis";
import useSimulatorFormStore from "@/store/simulator.form";

export default {
  name: "SimulatorPayinFormPage3",
  mounted() {
    if (this.simulatorForm.form == null) {
      this.$router.go(-1);
    } else {
      this.form = this.simulatorForm.form;
      this.startCountDown();
    }
  },
  methods: {
    startCountDown() {
      this.timer = setInterval(() => {
        if (this.countDown > 0) {
          this.countDown--;
        } else {
          clearInterval(this.timer);
          // this.sound.play()
          // this.reset()
        }
      }, 1000);
    },
    confirm() {
      this.form.isSuccess = 1;
      this.simulatorForm.setForm(this.form);
      this.$router.push({ name: "Simulator Payin Result" });
    },
    cancel() {
      this.form.isSuccess = 0;
      this.simulatorForm.setForm(this.form);
      this.$router.push({ name: "Simulator Payin Result" });
    },
  },
  data: () => ({
    v$: useValidate(),
    simulatorForm: useSimulatorFormStore(),
    loading: false,
    timer: "",
    countDown: 30,
    isError: false,
    errorMsg: "",
    status: "",
    form: {
      isSuccess: 1,
      PaymentId: {
        product_name: "",
      },
    },
  }),
  computed: {},
  watch: {
    "form.MerchantCode": function (newVal) {
      RequestService.postRequest(e2payApis.GET_LINKED_CHANNEL, {
        type: "payin",
        partnerId: newVal.code,
      }).then((response) => {
        if (response.data.code == 1) {
          this.refChannels = response.data.data;
          this.loading = false;
        } else {
          this.loading = false;
          this.isError = true;
          this.errorMsg = response.data.message;
        }
      });
    },
  },
};
</script>
