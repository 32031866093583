<template>
  <v-dialog persistent v-model="dialog" height="auto">
    <div class="card card-frame">
      <div v-if="loading" style="height: 182px">
        <div
          class="text-center d-flex align-items-center justify-content-center h-100"
        >
          <img
            width="10"
            class="img-fluid"
            :src="imageLoading"
            style="width: 80px; height: 50px"
          />
        </div>
      </div>
      <div v-else>
        <div class="card-header">
          <span class="text-h5">Detail Manual Disbursement</span>
        </div>
        <div class="card-body">
          <EasyDataTable
            show-index
            alternating
            :headers="headers"
            :items="data"
            :loading="isLoadingList"
            hide-footer
          >
            <template #item-disburse_status_desc="item">
              <span
                v-if="item.disburse_status_desc == 'ON PROCESS'"
                class="badge bg-info text-white text-bold"
                >{{ item.disburse_status_desc }}</span
              >
              <span
                v-if="item.disburse_status_desc == 'ALL SUCCESS'"
                class="badge bg-success text-white text-bold"
                >{{ item.disburse_status_desc }}</span
              >
              <span
                v-if="item.disburse_status_desc == 'SUCCESS'"
                class="badge bg-success text-white text-bold"
                >{{ item.disburse_status_desc }}</span
              >
              <span
                v-if="item.disburse_status_desc == 'ALL FAILED'"
                class="badge bg-error text-white text-bold"
                >{{ item.disburse_status_desc }}</span
              >
              <span
                v-if="item.disburse_status_desc == 'FAILED'"
                class="badge bg-error text-white text-bold"
                >{{ item.disburse_status_desc }}</span
              >
              <span
                v-if="item.disburse_status_desc == 'PARTIAL SUCCESS'"
                class="badge bg-warning text-white text-bold"
                >{{ item.disburse_status_desc }}</span
              >
              <span
                v-if="item.disburse_status_desc == 'PARTIAL FAILED'"
                class="badge bg-warning text-white text-bold"
                >{{ item.disburse_status_desc }}</span
              >
            </template>
          </EasyDataTable>

          <div class="row mt-2" v-if="isShowButtonApproval">
            <div class="form-group">
              <label class="form-control-label">Issuer</label>
              <VueMultiselect
                :multiple="false"
                v-model="form.issuerCode"
                :options="issuerOptions"
                label="pg_name"
                track-by="pg_code"
                placeholder="Pick Issuer"
                :disabled="this.isLoadingIssuer"
              >
              </VueMultiselect>
            </div>
            <div class="form-group">
              <label class="form-control-label">Remarks</label>
              <textarea
                rows="3"
                class="form-control"
                v-model="form.remarks"
                placeholder="remarks"
              >
              </textarea>
            </div>
          </div>

          <div class="row mt-2 text-center">
            <div class="col-12 col-md-12">
              <div
                class="d-flex align-items-center justify-content-center"
                style="gap: 8px"
              >
                <button
                  v-if="isShowButtonApproval"
                  @click="submitApproval('APPROVE')"
                  class="btn btn-block btn-success mb-0 mr-0"
                  :disabled="isSubmittingApproved || isSubmittingRejected"
                >
                  {{ isSubmittingApproved ? "APPROVING..." : "APPROVE" }}
                </button>
                <button
                  v-if="isShowButtonApproval"
                  @click="submitApproval('REJECT')"
                  class="btn btn-block btn-danger mb-0 mr-0"
                  :disabled="isSubmittingApproved || isSubmittingRejected"
                >
                  {{ isSubmittingRejected ? "REJECTING..." : "REJECT" }}
                </button>
                <button
                  @click="this.$emit('closeDialog')"
                  class="btn btn-block btn-info mb-0 mr-0"
                  :disabled="isSubmittingApproved || isSubmittingRejected"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import dashboardService from "@/services/dashboard.service";

export default {
  name: "ModalAdminManualDisbursement",
  props: {
    openDialog: Boolean,
    data: Array,
    loading: Boolean,
    isShowButtonApproval: Boolean,
  },
  methods: {
    getAllIssuers() {
      this.isLoadingIssuer = true;
      dashboardService.getAllIssuers().then((response) => {
        if (response.data.code == 1) {
          this.issuerOptions = response.data.data;
          this.isLoadingIssuer = false;
        } else {
          this.isLoadingIssuer = false;
        }
      });
    },
    submitApproval(status) {
      if (status === "APPROVE") {
        this.isSubmittingApproved = true;
        this.isSubmittingRejected = false;
      } else {
        this.isSubmittingApproved = false;
        this.isSubmittingRejected = true;
      }

      const payload = {
        idSummary: this.data.disburse_summary_id,
        disburseStatus: status === "APPROVE" ? 80 : 96,
        ...(status === "APPROVE" && {
          issuerCode: this.form.issuerCode?.pg_code,
        }),
        remarks: this.form.remarks,
      };

      dashboardService
        .approveManualDisbursement(payload)
        .then((response) => {
          if (response.data.code == 1) {
            this.isSuccess = true;
            this.message = response.data.message;
            setTimeout(() => {
              this.$emit("closeDialog");
              this.$emit("fetchData");
              this.isSuccess = false;
              this.message = "";
            }, 2000);
          } else {
            this.isError = true;
            this.message = response.data.message;
            setTimeout(() => {
              this.isError = false;
              this.message = "";
            }, 2000);
          }
        })
        .finally(() => {
          this.form = {
            issuerCode: "",
            remarks: "",
          };
          this.isSubmittingApproved = false;
          this.isSubmittingRejected = false;
        });
    },
  },
  data: () => ({
    imageLoading: require("@/assets/img/cube_loading.gif"),
    dialog: false,
    isSuccess: false,
    isError: false,
    message: "",
    isSubmittingApproved: false,
    isSubmittingRejected: false,
    isLoadingIssuer: false,
    issuerOptions: [],
    form: {
      issuerCode: "",
      remarks: "",
    },
    headers: [
      { text: "Transaction Ref No", value: "transaction_ref_no" },
      { text: "Merchant", value: "partner_name" },
      { text: "Issuer", value: "issuer_name" },
      { text: "Status", value: "disburse_status_desc" },
      { text: "Bank Name", value: "bank_desc" },
      { text: "Benry Account No.", value: "benry_account_number" },
      { text: "Benry Account Name", value: "benry_account_name" },
      { text: "Disbursed At", value: "disbursed_at" },
      { text: "Amount (IDR)", value: "gross_amount" },
      { text: "Fee Amount (IDR)", value: "fee_amount" },
      { text: "Total Amount (IDR)", value: "total_amount" },
      { text: "Requestor Remarks", value: "requestor_remarks" },
      { text: "Disbursement Remarks", value: "disburse_remarks" },
    ],
  }),
  emits: ["closeDialog", "fetchData"],
  watch: {
    openDialog: function (newVal) {
      this.dialog = newVal;
    },
    data: function () {
      if (this.isShowButtonApproval) {
        this.getAllIssuers();
      }
    },
  },
};
</script>
