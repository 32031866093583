<template>
  <main class="main-content">
    <div class="container">
      <div class="center">
        <div class="card mt-12">
          <div class="card-body text-center">
            <div>
              <img
                src="assets/img/loading.gif"
                class="img-fluid"
                alt="Responsive image"
              />
            </div>
            <p>
              <i
                >You'll be redirected to merchant site shortly in
                {{ remainingTime }}</i
              >
            </p>
            <p class="text-danger"><strong>DO NOT CLOSE THIS WINDOW</strong></p>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "PaymentRedirectPage",
  created() {
    document.title = "Payment Redirect";
    const link = document.createElement("link");
    link.rel = "icon";
    link.href = "/favicon-co.ico"; // Update with the actual path to your custom favicon
    document.head.appendChild(link);
  },
  mounted() {
    if (this.$route.query.redirectUrl == undefined) {
      this.$router.go(-1);
    } else {
      this.timer = setInterval(() => {
        if (this.remainingTime > 0) {
          this.remainingTime--;
        } else {
          clearInterval(this.timer);
          window.location.replace(this.$route.query.redirectUrl);
        }
      }, 1000);
    }
  },
  components: {},
  computed: {},
  methods: {},
  data: () => ({
    isSuccess: false,
    loading: false,
    timer: null,
    remainingTime: 5,
  }),
};
</script>

<style scoped>
.checkmark {
  color: #9abc66;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}
.crossmark {
  color: #ef1616;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}
.subtitle {
  color: #404f5e;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  margin: 0;
}
.success-title {
  color: #88b04b;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}
.failed-title {
  color: #ef1616;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}
</style>
