<template>
  <main class="main-content">
    <div
      class="container"
      style="
        padding-left: 16px !important;
        padding-right: 16px !important;
        box-sizing: border-box;
      "
    >
      <div class="alert alert-danger" role="alert" v-if="isNotFound">
        Transaction not found
      </div>
      <div
        class="spinner-border"
        style="width: 3rem; height: 3rem"
        role="status"
        v-if="loading"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="center" v-else>
        <div class="mt-4">
          <div class="row mb-4">
            <div class="col-12 text-center">
              <div
                class="d-flex flex-column justify-content-center align-items-center"
                style="margin-bottom: 24px"
              >
                <img
                  src="/assets/img/qris.png"
                  class="img-fluid"
                  style="width: 150px; height: 150px"
                />
                <qrcode-vue
                  :value="qrString"
                  :size="qrSize"
                  level="H"
                  class="qrvalue-style"
                />

                <h4 style="margin-bottom: 16px; margin-top: 24px">
                  Remaining Time
                </h4>
                <h1 style="margin-bottom: 16px">
                  <strong>{{ getRemainingTime }}</strong>
                </h1>

                <div
                  class="row"
                  style="width: 100%; max-width: 320px; margin-bottom: 16px"
                >
                  <div class="col-7 text-left" style="padding: 0 !important">
                    Transaction Amount
                  </div>
                  <div class="col-1 text-center" style="padding: 0 !important">
                    :
                  </div>
                  <div class="col-4 text-left" style="padding: 0 !important">
                    {{ formattingNumber(form.amount) }}
                  </div>
                </div>

                <div
                  class="row"
                  style="width: 100%; max-width: 320px; margin-bottom: 16px"
                >
                  <div class="col-7 text-left" style="padding: 0 !important">
                    Order ID
                  </div>
                  <div class="col-1 text-center" style="padding: 0 !important">
                    :
                  </div>
                  <div class="col-4 text-left" style="padding: 0 !important">
                    {{ form.paymentRefNo }}
                  </div>
                </div>

                <button
                  type="button"
                  class="btn btn-outline-primary"
                  @click="goToMerchant"
                >
                  Back to merchant
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import RequestService from "@/services/request.service";
import e2payApis from "@/services/e2pay-apis";
import { formatNumber } from "@/services/number-format";
import QrcodeVue from "qrcode.vue";

export default {
  name: "PaymentPage",
  created() {
    document.title = "Checkout Page";
    const link = document.createElement("link");
    link.rel = "icon";
    link.href = "/favicon-co.ico"; // Update with the actual path to your custom favicon
    document.head.appendChild(link);
  },
  mounted() {
    if (this.$route.query.transId == undefined) {
      this.$router.go(-1);
    } else {
      this.fetchData();
      this.repeater = setInterval(() => {
        this.fetchData();
        if (this.remainingTime <= 0) {
          this.$router.push(
            "/payment-failed?status=2&redirectUrl=" + this.responseUrl
          );
        } else if (this.status == 1) {
          this.$router.push(
            "/payment-success?isSuccess=1&redirectUrl=" + this.responseUrl
          );
        } else if (this.status > 1) {
          this.$router.push(
            "/payment-success?isSuccess=0&redirectUrl=" + this.responseUrl
          );
        }
      }, 10000);
    }
  },
  beforeUnmount() {
    clearInterval(this.repeater);
  },
  components: {
    QrcodeVue,
  },
  computed: {
    getRemainingTime: function () {
      let time = this.remainingTime / 60;
      let minutes = parseInt(time);
      let secondes = Math.round((time - minutes) * 60);
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (secondes < 10) {
        secondes = "0" + secondes;
      }
      return minutes + ":" + secondes;
    },
  },
  methods: {
    formattingNumber(val) {
      return "Rp." + formatNumber(val, 0) + ",-";
    },
    fetchData() {
      this.loading = true;
      RequestService.postRequest(
        e2payApis.GET_QR_DETAIL + this.$route.query.transId,
        this.searchParam,
        false
      ).then((response) => {
        let resp = response.data.data;
        if (resp && resp.length > 0) {
          // this.form.invoiceNo = resp[0].invoice_no;
          this.form.paymentRefNo = resp[0].payment_ref_no;
          this.form.amount = resp[0].amount;
          this.qrString = resp[0].qr_string;
          let dateExp = new Date(resp[0].qr_expiry_timestamp);
          let dateNow = new Date(resp[0].serverTime.date);
          this.isExpired = dateNow > dateExp;
          var diff = Math.abs(dateExp - dateNow);
          this.remainingTime = diff / 1000;
          this.responseUrl = resp[0].response_url;
          this.status = resp[0].paid_status;
        } else {
          this.isNotFound = true;
        }
        this.loading = false;
        if (this.status == 1) {
          this.$router.push(
            "/payment-success?isSuccess=1&status=2&redirectUrl=" +
              this.responseUrl
          );
        } else if (this.isExpired) {
          this.$router.push(
            "/payment-failed?status=2&redirectUrl=" + this.responseUrl
          );
        } else if (this.remainingTime <= 0) {
          this.$router.push(
            "/payment-success?isSuccess=0&status=1&redirectUrl=" +
              this.responseUrl
          );
        } else {
          this.start();
        }
      });
    },
    start() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.remainingTime > 0) {
            this.remainingTime--;
          } else {
            clearInterval(this.timer);
            this.$router.push(
              "/payment-failed?status=2&redirectUrl=" + this.responseUrl
            );
          }
        }, 1000);
      }
    },
    goToMerchant() {
      this.$router.push("/payment-redirect?redirectUrl=" + this.responseUrl);
    },
  },
  data: () => ({
    loading: false,
    isNotFound: false,
    isExpired: false,
    repeater: null,
    status: false,
    timer: null,
    qrString: "",
    qrSize: 300,
    remainingTime: 0,
    responseUrl: "",
    form: {
      // invoiceNo: "",
      paymentRefNo: "",
      amount: 0,
    },
  }),
};
</script>

<style scoped>
.qrvalue-style {
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 640px) {
  .qrvalue-style {
    width: 250px !important;
    height: 250px !important;
  }
}
</style>
