<template>
  <div class="card card-frame">
    <div class="card-header">
      <h6 class="font-weight-bolder mb-0">Simulator Payout Page 1</h6>
    </div>
    <div class="card-body">
      <div class="row" v-if="isError">
        <div class="col">
          <div
            class="alert alert-danger alert-dismissible fade show"
            role="alert"
          >
            <span class="alert-text text-white"
              ><strong>Error!</strong> {{ errorMsg }}</span
            >
            <button
              class="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>
      <form>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label">Merchant</label>
              <VueMultiselect
                :multiple="false"
                v-model="form.MerchantCode"
                :options="refMerchants"
                label="name"
                track-by="code"
                placeholder="Pick Merchant"
              >
              </VueMultiselect>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label">Bank Name</label>
              <VueMultiselect
                :multiple="false"
                v-model="form.PaymentId"
                :options="refChannels"
                label="product_name"
                track-by="payment_id"
                placeholder="Pick channel"
              >
              </VueMultiselect>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label">Bank Account Name</label>
              <input
                type="text"
                class="form-control"
                v-model="form.BankAccountName"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label">Bank Account No</label>
              <input
                type="text"
                class="form-control"
                v-model="form.BankAccountNo"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label">Mobile No</label>
              <input
                type="text"
                class="form-control"
                v-model="form.CustomerId"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label">Amount</label>
              <input type="number" class="form-control" v-model="form.Amount" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="form-control-label">Remarks</label>
              <textarea
                rows="3"
                class="form-control"
                v-model="form.remarks"
                placeholder="Remarks"
              >
              </textarea>
            </div>
          </div>
          <div></div>
        </div>
      </form>
      <div class="row mt-2">
        <div class="col-12 col-md-12">
          <button
            @click="next()"
            class="btn btn-success mr-2"
            :disabled="loading"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import useValidate from "@vuelidate/core";
import RequestService from "@/services/request.service";
import e2payApis from "@/services/e2pay-apis";
import useSimulatorFormStore from "@/store/simulator.form";

export default {
  name: "SimulatorPayoutFormPage1",
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      RequestService.postRequest(e2payApis.GET_LINKED_PARTNER, {}).then(
        (response) => {
          if (response.data.code == 1) {
            this.refMerchants = response.data.data;
            this.loading = false;
          } else {
            this.loading = false;
            this.isError = true;
            this.errorMsg = response.data.message;
          }
        }
      );
    },
    next() {
      RequestService.postRequest(e2payApis.GET_FEE, {
        partnerId: this.form.MerchantCode.code,
        paymentId: this.form.PaymentId.payment_id,
        mode: "payout",
        amount: this.form.Amount,
      }).then((response) => {
        if (response.data != null) {
          this.loading = false;
          this.form.AdminFee = response.data;
          this.simulatorForm.setForm(this.form);
          this.$router.push({ name: "Simulator Payout Page 2" });
        } else {
          this.loading = false;
          this.isError = true;
          this.errorMsg = "Admin Fee Not Found";
        }
      });
    },
  },
  data: () => ({
    v$: useValidate(),
    simulatorForm: useSimulatorFormStore(),
    loading: false,
    registerMessage: "",
    isError: false,
    errorMsg: "",
    status: "",
    refChannels: [],
    refMerchants: [],
    refCurrencies: ["IDR"],
    refLanguages: ["ENG"],
    form: {
      PaymentId: {
        category: "",
      },
      MerchantCode: "",
      BankAccountName: "",
      BankAccountNo: "",
      CustomerId: "",
      Amount: "",
      Remarks: "",
      AdminFee: 0,
      Signature: "0r2iur4saIcECrvV0t/8cqO0cYo=",
    },
  }),
  watch: {
    "form.MerchantCode": function (newVal) {
      RequestService.postRequest(e2payApis.GET_LINKED_CHANNEL, {
        type: "payout",
        partnerId: newVal.code,
      }).then((response) => {
        if (response.data.code == 1) {
          this.refChannels = response.data.data;
          this.loading = false;
        } else {
          this.loading = false;
          this.isError = true;
          this.errorMsg = response.data.message;
        }
      });
    },
    "form.PaymentId": function () {},
  },
};
</script>
