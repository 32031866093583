<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card mb-12">
          <div class="card-header pb-0">
            <div class="row">
              <div class="col">
                <h6>Data {{ currentRouteName }}</h6>
              </div>
              <div class="col">
                <router-link
                  to="/merchant-channel-issuer/update"
                  class="btn bg-primary float-right margin-0 pd-10"
                >
                  UPDATE MAPPING ISSUER
                </router-link>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body p-3">
              <TableFilter
                :f_submit="searchSubmit"
                :f_loading="loading"
                :f_partner_id="true"
                :f_mapping_issuer="true"
                :f_payment_id="true"
                :f_pg_id="true"
              ></TableFilter>
            </div>
            <div class="card-body p-3">
              <EasyDataTable
                show-index
                buttons-pagination
                alternating
                v-model:server-options="serverOptions"
                :server-items-length="numberOfPages"
                :loading="loading"
                :headers="headers"
                :items="merchantChannelIssuer"
              >
              </EasyDataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardService from "@/services/dashboard.service";
import TableFilter from "@/views/TableFilter";

export default {
  name: "MerchantChannelIssuerList",
  components: {
    TableFilter,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    searchSubmit(dataParam) {
      this.searchParam = {
        ...dataParam,
        filter_partner_id: dataParam?.filter_partner_id?.partner_id || "",
        filter_payment_id: dataParam?.filter_payment_id?.payment_id || "",
        filter_pg_id: dataParam?.filter_pg_id?.pg_id || "",
      };
      this.searchParam.serverOptions = this.serverOptions;
      this.fetchData();
    },
    fetchData() {
      this.loading = true;
      this.searchParam.serverOptions = this.serverOptions;
      DashboardService.getChannelMapping(this.searchParam).then((response) => {
        this.merchantChannelIssuer = response.data.data.data;
        this.numberOfPages = response.data.data.dataCount;
        this.loading = false;
      });
    },
  },
  data: () => ({
    imageLoading: require("@/assets/img/cube_loading.gif"),
    merchantChannelIssuer: [],
    numberOfPages: 0,
    loading: false,
    headers: [
      { text: "Merchant", value: "partner_name" },
      { text: "Channel", value: "product_name" },
      { text: "Issuer", value: "pg_name" },
    ],
    searchParam: {},
    serverOptions: {
      page: 1,
      rowsPerPage: 25,
      sortBy: "",
      sortType: "",
    },
  }),
  mounted() {
    this.fetchData();
  },
  watch: {
    serverOptions: {
      handler() {
        this.searchParam.serverOptions = this.serverOptions;
        this.fetchData(this.searchParam);
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>
