<template>
  <main
    class="main-content position-relative max-height-vh-100 h-100 border-radius-lg"
  >
    <!-- Navbar -->
    <NavigationTop pages="Dashboard" subpages="Dashboard"></NavigationTop>
    <!-- End Navbar -->
    <div class="container-fluid py-4">
      <div class="row">
        <div class="col-12">
          <ToppayNotification :menu="'dashboard'"></ToppayNotification>
        </div>
      </div>
      <div class="row">
        <TopItem
          :item="balanceData"
          :splitPayoutProp="isSplitPayout"
          @filter-date="filterByDate"
        ></TopItem>
      </div>
      <!-- <div class="row">
        <div class="col-md-6">
          <TransactionChart :loading="loadingChart" :chartdata=chartData1 chartTitle="Based On Trx"></TransactionChart>
        </div>
        <div class="col-md-6">
          <TransactionChart :loading="loadingChart" :chartdata=chartData2 chartTitle="Based On Value"></TransactionChart>
        </div>
      </div> -->
      <div class="row my-4">
        <div class="col-lg-8 col-md-6 mb-md-0 mb-4" v-if="roleId == 0">
          <div class="card">
            <div class="card-header pb-0">
              <div class="row">
                <div class="col-lg-6 col-7">
                  <h6>Information</h6>
                  <p class="text-sm mb-0">
                    <i class="fa fa-info text-info" aria-hidden="true"></i>
                    <span class="font-weight-bold ms-1">Generated at: </span>
                    {{ infodate }}
                  </p>
                </div>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th
                        class="text-uppercase text-black text-xxs font-weight-bolder opacity-7"
                      >
                        Partner Name
                      </th>
                      <!-- <th class="text-uppercase text-black text-xxs font-weight-bolder opacity-7 ps-2">Wallet</th> -->
                      <th
                        class="text-center text-uppercase text-black text-xxs font-weight-bolder opacity-7"
                      >
                        Disbursment
                      </th>
                      <th
                        class="text-center text-uppercase text-black text-xxs font-weight-bolder opacity-7"
                      >
                        Ready To Settle
                      </th>
                      <th
                        class="text-center text-uppercase text-black text-xxs font-weight-bolder opacity-7"
                      >
                        Receivables
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(data, index) in balanceData.partner"
                      :key="index"
                    >
                      <td>
                        <div class="d-flex px-2 py-1">
                          <div>
                            <i
                              class="fa fa-shopping-cart"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div
                            class="ml-1 d-flex flex-column justify-content-center"
                          >
                            <h6 class="mb-0 text-sm">
                              <span class="badge bg-success p-1">{{
                                index.replace("_", " ").toUpperCase()
                              }}</span>
                              {{ data.name }}
                            </h6>
                          </div>
                        </div>
                      </td>
                      <!-- <td class="align-middle text-center text-sm">
                      <span class="text-xs font-weight-bold"> {{data.wallet}} </span>
                    </td> -->
                      <td class="align-middle text-center text-sm">
                        <span class="text-xs font-weight-bold">
                          {{ data.disbursment }}
                        </span>
                      </td>
                      <td class="align-middle">
                        <span class="text-xs font-weight-bold">
                          {{ data.ready_to_settle }}
                        </span>
                      </td>
                      <td class="align-middle">
                        <span class="text-xs font-weight-bold">
                          {{ data.receivables }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="{
            'col-lg-4 col-md-6': roleId == 0,
            'col-lg-12 col-md-12': roleId != 0,
          }"
        >
          <HolidayData></HolidayData>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import DashboardService from "@/services/dashboard.service";
import NavigationTop from "@/components/NavigationTop";
import TopItem from "@/views/TopItem";
import HolidayData from "@/views/HolidayData";
import ToppayNotification from "@/components/ToppayNotification";
// import TransactionChart from  "@/components/TransactionChart";
import dashboardService from "@/services/dashboard.service";
import moment from "moment";

export default {
  name: "DashboardRightContent",
  components: {
    TopItem,
    NavigationTop,
    HolidayData,
    ToppayNotification,
    // TransactionChart
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    getWalletBalance2() {
      var startDate = moment([moment().year(), moment().month()]);
      var endDate = moment(startDate).endOf("month");
      let param = {
        filter_date_range_start: startDate.format("yyyy-MM-DD"),
        filter_date_range_end: endDate.format("yyyy-MM-DD"),
      };
      DashboardService.getBalanceInfo(param).then((response) => {
        this.balanceData = response.data.data;
      });
    },
    getChartData1() {
      var startDate = moment([moment().year(), moment().month()]);
      var endDate = moment(startDate).endOf("month");
      let param = {
        filter_date_range_start: startDate.format("yyyy-MM-DD"),
        filter_date_range_end: endDate.format("yyyy-MM-DD"),
      };
      DashboardService.getChartDataSatu(param).then((response) => {
        if (response.data.data !== undefined) {
          this.chartData1 = response.data.data.chart1;
          this.chartData2 = response.data.data.chart2;
        }
        this.loadingChart = false;
      });
    },
    filterByDate(dateStart, dateEnd) {
      let param = {
        filter_date_range_start: dateStart,
        filter_date_range_end: dateEnd,
      };
      DashboardService.getBalanceInfo(param).then((response) => {
        this.balanceData = response.data.data;
      });
      DashboardService.getChartDataSatu(param).then((response) => {
        this.chartData1 = response.data.data.chart1;
        this.chartData2 = response.data.data.chart2;
        this.loadingChart = false;
      });
    },
  },
  data: () => ({
    roleId: dashboardService.getUserRoleId(),
    imageLoading: require("@/assets/img/cube_loading.gif"),
    loadingChart: true,
    loading_wallet: true,
    loading_settle: true,
    loading_disbursment: true,
    loading_trx: true,
    balanceWallet: 0,
    balanceDisbursment: 0,
    readySettle: 0,
    todayTrx: 0,
    infodate: "..",
    balanceData: [],
    chartData1: [],
    chartData2: [],
    isSplitPayout: 1,
  }),
  beforeMount() {
    this.getWalletBalance2();
    // this.getChartData1();
    const loggedIn = localStorage.getItem("user");
    if (loggedIn != null) {
      let dataUser = JSON.parse(loggedIn);
      this.isSplitPayout = dataUser.split_payout;
    }
  },
  watch: {
    balanceData: {
      immediate: false,
      deep: true,
      handler() {
        this.infodate = this.balanceData.info.date;
      },
    },
  },
};
</script>

<style scoped></style>
