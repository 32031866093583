<template>
  <span
    v-if="loading"
    class="spinner-border spinner-border-sm text-info"
  ></span>
  <div class="card card-frame" v-else>
    <div class="card-header">
      <h6 class="font-weight-bolder mb-0">Single Disbursement Form</h6>
    </div>
    <div class="card-body">
      <div class="row" v-if="isError && !isSuccess && message">
        <div class="col">
          <div
            class="alert alert-danger alert-dismissible fade show"
            role="alert"
          >
            <span class="alert-text text-white">
              <strong>Error!</strong>
              <br />
              {{ message }}
            </span>
            <button
              class="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>
      <div class="row" v-if="!isError && isSuccess && message">
        <div class="col">
          <div
            class="alert alert-success alert-dismissible fade show"
            role="alert"
          >
            <span class="alert-text text-white">
              <strong>Success!</strong>
              <br />
              {{ message }}
            </span>
            <button
              class="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>
      <form>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label">Bank Name</label>
              <VueMultiselect
                :multiple="false"
                :class="{ 'is-invalid': v$.form.PaymentId.$error }"
                v-model="form.PaymentId"
                :options="refChannels"
                label="product_name"
                track-by="payment_id"
                placeholder="Pick Bank"
              >
              </VueMultiselect>
              <span class="invalid-feedback" v-if="v$.form.PaymentId.$error">{{
                v$.form.PaymentId.$errors[0].$message
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label"
                >Bank Account No./Mobile Number</label
              >
              <input
                type="text"
                class="form-control"
                :class="{ 'is-invalid': v$.form.CustomerId.$error }"
                v-model="form.CustomerId"
              />
              <span class="invalid-feedback" v-if="v$.form.CustomerId.$error">{{
                v$.form.CustomerId.$errors[0].$message
              }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label">Receiver Name</label>
              <input
                type="text"
                :class="{ 'is-invalid': v$.form.CustomerName.$error }"
                class="form-control"
                v-model="form.CustomerName"
              />
              <span
                class="invalid-feedback"
                v-if="v$.form.CustomerName.$error"
                >{{ v$.form.CustomerName.$errors[0].$message }}</span
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label">Amount</label>
              <input
                type="number"
                :class="{ 'is-invalid': v$.form.Amount.$error }"
                class="form-control"
                v-model="form.Amount"
              />
              <span class="invalid-feedback" v-if="v$.form.Amount.$error">{{
                v$.form.Amount.$errors[0].$message
              }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="form-control-label">Remarks</label>
              <textarea
                rows="3"
                class="form-control"
                v-model="form.Remarks"
                placeholder="remarks"
              >
              </textarea>
            </div>
          </div>
        </div>
      </form>
      <div class="row mt-2">
        <div class="col-12 col-md-12">
          <button
            @click="submit()"
            class="btn btn-success d-flex align-items-center"
            :disabled="isSubmitting"
          >
            <span
              v-show="isSubmitting"
              class="spinner-border spinner-border-sm mr-2"
            ></span>
            {{ isSubmitting ? "Requesting..." : "Request" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import useValidate from "@vuelidate/core";
import RequestService from "@/services/request.service";
import DashboardService from "@/services/dashboard.service";
import e2payApis from "@/services/e2pay-apis";
import { required } from "@vuelidate/validators";

export default {
  name: "SingleDisbursementForm",
  mounted() {
    this.fetchData();
  },
  methods: {
    submit() {
      this.v$.form.$validate();
      if (!this.v$.form.$error) {
        const payload = {
          ...this.form,
          payment_id: this.form.PaymentId
            ? this.form.PaymentId?.payment_id
            : "",
        };
        this.isSubmitting = true;
        DashboardService.requestSingleDisbursement(payload)
          .then((response) => {
            if (response.data.code == 1) {
              this.isSuccess = true;
              this.isError = false;
              this.message = response.data.message;
              this.form = {
                PaymentId: undefined,
                CustomerId: "",
                CustomerName: "",
                Amount: undefined,
                Remarks: "",
              };
              setTimeout(() => {
                this.$router.push("/manual-disbursement");
                this.isSuccess = false;
                this.isError = false;
                this.message = "";
              }, 2000);
            } else {
              this.isSuccess = false;
              this.isError = true;
              this.message = response.data.message;
              setTimeout(() => {
                this.isSuccess = false;
                this.isError = false;
                this.message = "";
              }, 2000);
            }
          })
          .finally(() => (this.isSubmitting = false));
      }
    },
    fetchData() {
      this.loading = true;
      RequestService.postRequest(e2payApis.GET_BANK_LIST, {})
        .then((response) => {
          if (response.data.code == 1) {
            this.refChannels = response.data.data;
          } else {
            this.isError = true;
            this.message = response.data.message;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  data: () => ({
    v$: useValidate(),
    isSubmitting: false,
    loading: true,
    isSuccess: false,
    isError: false,
    message: "",
    status: "",
    refChannels: [],
    form: {
      PaymentId: undefined,
      CustomerId: "",
      CustomerName: "",
      Amount: undefined,
      Remarks: "",
    },
  }),
  validations() {
    return {
      form: {
        PaymentId: { required },
        CustomerId: { required },
        CustomerName: { required },
        Amount: { required },
        Remarks: { required },
      },
    };
  },
  watch: {},
};
</script>
<style>
.is-invalid .multiselect__tags {
  border-color: #fd5c70 !important;
  padding-right: unset;
  /* background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23fd5c70' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23fd5c70' stroke='none'/%3e%3c/svg%3e);
      background-repeat: no-repeat;
      background-position: right 0.75rem center;
      background-size: 1rem 1rem; */
}
</style>
