<template>
  <div class="card card-frame">
    <div class="card-header">
      <button
        class="btn btn-icon btn-3 btn-outline-primary mt-2"
        @click="$router.go(-1)"
      >
        <span class="btn-inner--icon"><i class="fa fa-arrow-left"></i></span>
      </button>
      Result Payin Simulator
    </div>
    <div class="card-body">
      <form>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label">Merchant Code</label>
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                :value="form.MerchantCode"
                placeholder="882"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label">Pay ID</label>
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                :value="form.PayID"
                placeholder="882"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label">Payment ID</label>
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                :value="form.PaymentId"
                placeholder="882"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label">Reference No</label>
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                :value="form.RefNo"
                placeholder="882"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label">Transaction ID</label>
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                :value="form.TransId"
                placeholder="882"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label">Status</label>
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                :value="form.Status"
                placeholder="882"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="form-control-label">Error Description</label>
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                :value="form.ErrDesc"
                placeholder="882"
              />
            </div>
          </div>
        </div>
      </form>
      <div class="row mt-2">
        <div class="col-12 col-md-12">
          <button
            @click="$router.go(-1)"
            class="btn btn-success mr-2"
            :disabled="loading"
          >
            Back
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import useValidate from "@vuelidate/core";

export default {
  name: "SimulatorPayinResult",
  props: {
    // paramModel: Object,
    // collapsed: Boolean,
  },
  components: {},
  mounted() {
    // this.fetchData();
    if (this.$route.params.result == undefined) {
      this.$router.go(-1);
    } else {
      this.form = JSON.parse(this.$route.params.result);
    }
  },
  methods: {},
  data: () => ({
    v$: useValidate(),
    loading: false,
    form: {},
  }),
};
</script>
