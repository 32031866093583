<template>
  <div class="row">
    <div class="col-sm-12 mt-sm-0 mt-4">
      <div class="card">
        <div class="col">
          <div class="card-header bg-gray-200">
            Edit Partners
            <button @click="doViewFormEditPartner">
              <i :class="partnerInfoIcon"></i>
            </button>
          </div>
          <div class="card-body bg-gray-100" v-show="partner_info_collapsed">
            <fieldset :disabled="loading_partner">
              <div class="mb-3">
                <label for="form_p_name" class="form-label">Partner Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="form_p_name"
                  v-model="dataJsonInfo.form_p_name"
                  placeholder="Partner Name"
                />
                <span v-if="v$.dataJsonInfo.form_p_name.$error">{{
                  v$.dataJsonInfo.form_p_name.$errors[0].$message
                }}</span>
              </div>
              <div class="mb-3">
                <label for="form_p_address" class="form-label"
                  >Partner Address</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="form_p_address"
                  v-model="dataJsonInfo.form_p_address"
                  placeholder="Partner Address"
                />
                <span v-if="v$.dataJsonInfo.form_p_address.$error">{{
                  v$.dataJsonInfo.form_p_address.$errors[0].$message
                }}</span>
              </div>
              <div class="mb-3">
                <label for="form_p_email" class="form-label"
                  >Email address</label
                >
                <input
                  type="email"
                  class="form-control"
                  id="form_p_email"
                  v-model="dataJsonInfo.form_p_email"
                  placeholder="name@example.com"
                />
                <span v-if="v$.dataJsonInfo.form_p_email.$error">{{
                  v$.dataJsonInfo.form_p_email.$errors[0].$message
                }}</span>
              </div>
              <div class="mb-3">
                <label for="form_p_phone" class="form-label">Telephone</label>
                <input
                  type="text"
                  class="form-control"
                  id="form_p_phone"
                  v-model="dataJsonInfo.form_p_phone"
                  placeholder="081234567890"
                />
                <span v-if="v$.dataJsonInfo.form_p_phone.$error">{{
                  v$.dataJsonInfo.form_p_phone.$errors[0].$message
                }}</span>
              </div>
              <div class="mb-3">
                <label for="form_p_phone" class="form-label">VA Pooling</label>
                <VueMultiselect
                  :multiple="false"
                  v-model="dataJsonInfo.form_p_vapooling"
                  :options="vapoolOptions"
                  :taggable="true"
                  label="name"
                  track-by="code"
                >
                </VueMultiselect>
                <span v-if="v$.dataJsonInfo.form_p_vapooling.$error">{{
                  v$.dataJsonInfo.form_p_vapooling.$errors[0].$message
                }}</span>
              </div>
              <div class="mb-3">
                <label for="form_p_ip" class="form-label">IP Address</label>
                <VueMultiselect
                  :multiple="true"
                  v-model="dataJsonInfo.form_p_ip"
                  :options="ipOptions"
                  :taggable="true"
                  label="name"
                  track-by="code"
                  @tag="addTag"
                >
                </VueMultiselect>
                <span v-if="v$.dataJsonInfo.form_p_ip.$error">{{
                  v$.dataJsonInfo.form_p_ip.$errors[0].$message
                }}</span>
              </div>
              <div class="mb-3">
                <label for="form_p_channel" class="form-label">Channel</label>
                <VueMultiselect
                  :multiple="true"
                  v-model="dataJsonInfo.form_p_channel"
                  :options="channelOptions"
                  :taggable="false"
                  label="name"
                  track-by="name"
                  @tag="addTag"
                >
                </VueMultiselect>
                <span v-if="v$.dataJsonInfo.form_p_channel.$error">{{
                  v$.dataJsonInfo.form_p_channel.$errors[0].$message
                }}</span>
              </div>
              <div
                class="form-group d-flex align-items-center justify-content-between"
              >
                <span class="text-sm text-bold">Activate partner?</span>
                <div class="form-check form-switch ms-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckDefault30"
                    v-model="dataJsonInfo.form_p_active"
                  />
                </div>
              </div>
            </fieldset>
            <span
              v-show="loading_partner"
              class="spinner-border spinner-border-sm"
            ></span>
            <div
              :class="classAlertAddPartner"
              role="alert"
              v-if="message.addpartner"
            >
              {{ message.addpartner }}
            </div>
            <div class="mb-3">
              <span
                v-show="loading_partner"
                class="spinner-border spinner-border-sm"
              ></span>
              <button
                @click="doSubmitEditPartner"
                class="btn btn-warning mr-2"
                :disabled="loading_partner"
                type="button"
              >
                Edit
              </button>
              <button
                @click="doClearInfo"
                class="btn btn-info"
                :disabled="loading_partner"
                type="button"
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardService from "@/services/dashboard.service";
import useValidate from "@vuelidate/core";
import { email, required, minLength, maxLength } from "@vuelidate/validators";
export default {
  name: "ClientEditPage",
  components: {},
  props: {
    item: undefined,
    infocolapsed: Boolean,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.ipOptions.push(tag);
    },
    getVaPooling() {
      this.loading = true;
      DashboardService.getVaPoolingListCode(this.dataJsonInfo).then(
        (response) => {
          if (response.data.code == 1) {
            this.vapoolOptions = response.data.data;
            this.loading = false;
          } else {
            this.loading = false;
          }
        }
      );
    },
    getPaymentListCode() {
      this.loading = true;
      DashboardService.getPaymentListCode(this.dataJsonInfo).then(
        (response) => {
          if (response.data.code == 1) {
            this.channelOptions = response.data.data;
            this.loading = false;
          } else {
            this.loading = false;
          }
        }
      );
    },
    doClearInfo() {
      this.dataJsonInfo.form_p_name = "";
      this.dataJsonInfo.form_p_address = "";
      this.dataJsonInfo.form_p_email = "";
      this.dataJsonInfo.form_p_phone = "";
      this.dataJsonInfo.form_p_ip = "";
      this.dataJsonInfo.form_p_channel = "";
      this.dataJsonInfo.form_p_active = "";
    },
    doViewFormEditPartner() {
      this.partner_info_collapsed = !this.partner_info_collapsed;
      if (this.partner_info_collapsed) {
        this.partnerInfoIcon = "fa-solid fa-chevron-up";
      } else {
        this.partnerInfoIcon = "fa-solid fa-chevron-down";
      }
    },
    doSubmitEditPartner() {
      this.v$.dataJsonInfo.$validate();
      if (!this.v$.dataJsonInfo.$error) {
        this.loading_partner = true;
        DashboardService.postEditPatner(this.dataJsonInfo).then((response) => {
          this.message.addpartner = response.message;
          if (response.code == 1) {
            this.classAlertAddPartner = "alert alert-success";
          } else {
            this.classAlertAddPartner = "alert alert-danger";
          }
          this.loading_partner = false;
        });
      } else {
        this.loading_partner = false;
        this.message.addpartner = this.v$.dataJsonInfo.$errors[0].$message;
      }
    },
  },
  data: () => ({
    v$: useValidate(),
    loading_partner: false,
    form: "",
    control: "",
    message: {
      addpartner: "",
    },
    partnerInfoIcon: "fa-solid fa-chevron-down",
    partner_info_collapsed: false,
    ipOptions: [],
    channelOptions: [
      { id: 25, text: "Dana Wallet" },
      { id: 26, text: "Bank Mega" },
    ],
    vapoolOptions: [{ code: 0, name: "Test" }],
    select2settingIp: {
      dropdownAutoWidth: false,
      tags: true,
      multiple: true,
      width: "100%",
    },
    select2settingP: {
      dropdownAutoWidth: false,
      multiple: true,
      width: "100%",
    },
    select2setting: {
      dropdownAutoWidth: false,
      multiple: false,
      width: "100%",
    },
    options: [
      { name: "Vue.js", code: "vu" },
      { name: "Javascript", code: "js" },
      { name: "Open Source", code: "os" },
    ],
    dataJsonInfo: {
      form_p_name: "",
      form_p_address: "",
      form_p_email: "",
      form_p_phone: "",
      form_p_ip: "",
      form_p_channel: "",
      form_p_active: false,
      form_p_vapooling: "",
      item: "",
    },
  }),
  validations() {
    return {
      dataJsonInfo: {
        form_p_name: { required, minLength: minLength(5) },
        form_p_address: { required, maxLength: maxLength(150) },
        form_p_email: { required, email },
        form_p_phone: { required },
        form_p_ip: { required },
        form_p_channel: { required },
        form_p_active: {},
        form_p_vapooling: {},
      },
    };
  },
  mounted() {
    this.loading_partner = true;
    this.getPaymentListCode();
    this.getVaPooling();
    this.loading_partner = false;
  },
  unmounted() {},
  watch: {
    item: {
      immediate: true,
      deep: true,
      handler() {
        this.ipOptions = this.item.partner_allow_ip_id;
        this.dataJsonInfo.item = this.item;
        this.dataJsonInfo.form_p_name = this.item.partner_name;
        this.dataJsonInfo.form_p_address = this.item.partner_address;
        this.dataJsonInfo.form_p_email = this.item.partner_email;
        this.dataJsonInfo.form_p_phone = this.item.partner_telp;
        this.dataJsonInfo.form_p_ip = this.item.partner_allow_ip_id;
        this.dataJsonInfo.form_p_channel = this.item.partner_allow_channel_id;
        this.dataJsonInfo.form_p_active = this.item.partner_status_id;
        this.dataJsonInfo.form_p_vapooling = this.item.partner_config;
      },
    },
  },
};
</script>
