// src/views/BulkDisbursement.vue
<template>
  <div class="main-content-panel">
    <!-- Left Menu Component -->
    <DashboardLeftMenu />

    <!-- Main Content -->
    <main class="main-content">
      <NavigationTop
        :pages="currentRouteName"
        :subpages="currentRouteName"
      ></NavigationTop>

      <!-- Card Container -->
      <div class="card card-frame">
        <div class="card-header" style="padding-bottom: 0">
          <h6 class="font-weight-bolder mb-0">Bulk Disbursement Form</h6>
        </div>

        <div class="card-body">
          <!-- Error Alert -->
          <div class="row" v-if="isError && message">
            <div class="col">
              <div
                class="alert alert-danger alert-dismissible fade show"
                role="alert"
              >
                <span class="alert-text text-white">
                  <strong>Disbursement Request Failed!</strong>
                  <br />
                  {{ message }}
                </span>
                <button
                  type="button"
                  class="btn-close"
                  @click="clearAlert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </div>

          <!-- Success Alert -->
          <div class="row" v-if="isSuccess && message">
            <div class="col">
              <div
                class="alert alert-success alert-dismissible fade show"
                role="alert"
              >
                <span class="alert-text text-white">
                  <strong>Disbursement Requested</strong>
                  <br />
                  {{ message }}
                </span>
                <button
                  type="button"
                  class="btn-close"
                  @click="clearAlert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </div>

          <!-- Template Download Section -->
          <h2 class="template-text">
            Don't have the Disbursement Template yet? Download
            <a @click="downloadTemplate('bulk')" class="template-link">CSV</a>
          </h2>

          <h2 class="template-text-2">
            For Beneficiary Bank Code list, please refer to this
            <a @click="downloadTemplate('bank')" class="template-link">file</a>
          </h2>

          <!-- File Upload Container -->
          <div
            class="upload-container border border-2 border-dashed rounded-3 p-4 text-center"
            @dragover.prevent="handleDragOver"
            @dragleave.prevent="handleDragLeave"
            @drop.prevent="handleDrop"
            :class="{ 'drag-over': isDragging }"
          >
            <!-- Empty State -->
            <div v-if="!selectedFile">
              <i class="bi bi-cloud-upload fs-1"></i>
              <h5 class="mt-3">Drag & Drop your file here</h5>
              <p class="text-muted">or</p>
              <label class="btn btn-primary mb-3">
                Browse File
                <input
                  type="file"
                  class="d-none"
                  @change="handleFileSelect"
                  ref="fileInput"
                  accept=".csv"
                />
              </label>
              <p class="text-muted small">
                Supported format: CSV (Max size: 2MB)
              </p>
            </div>

            <!-- File Selected State -->
            <div v-else class="selected-file">
              <i class="bi bi-file-earmark-text fs-1"></i>
              <h6 class="mt-3">{{ selectedFile.name }}</h6>
              <p class="text-muted small">
                {{ formatFileSize(selectedFile.size) }}
              </p>

              <!-- Action Buttons -->
              <div class="d-flex justify-content-center gap-2">
                <button
                  class="btn btn-success btn-sm"
                  @click="handleUpload"
                  :disabled="isUploading"
                >
                  <span
                    v-if="isUploading"
                    class="spinner-border spinner-border-sm me-2"
                  ></span>
                  {{ isUploading ? "Requesting..." : "Request" }}
                </button>
                <button
                  class="btn btn-danger btn-sm"
                  @click="removeFile"
                  :disabled="isUploading"
                >
                  Remove
                </button>
              </div>
            </div>

            <!-- Upload Progress -->
            <div v-if="uploadProgress > 0" class="mt-3">
              <div class="progress">
                <div
                  class="progress-bar"
                  role="progressbar"
                  :style="{ width: uploadProgress + '%' }"
                  :aria-valuenow="uploadProgress"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {{ uploadProgress }}%
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";
import DashboardLeftMenu from "@/components/DashboardLeftMenu";
import NavigationTop from "@/components/NavigationTop";
import { useRouter } from "vue-router";
import axios from "axios";
import e2payApis from "@/services/e2pay-apis";

export default {
  name: "BulkDisbursementIndex",
  components: {
    DashboardLeftMenu,
    NavigationTop,
  },

  setup() {
    const router = useRouter();

    // Refs
    const isDragging = ref(false);
    const selectedFile = ref(null);
    const uploadProgress = ref(0);
    const fileInput = ref(null);
    const isUploading = ref(false);
    const isError = ref(false);
    const isSuccess = ref(false);
    const message = ref("");
    const fileBase64 = ref(null);

    // Constants
    const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB

    // Convert file to base64
    const convertToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64String = reader.result.split(",")[1];
          resolve(base64String);
        };
        reader.onerror = (error) => reject(error);
      });
    };

    // Clear alerts
    const clearAlert = () => {
      isError.value = false;
      isSuccess.value = false;
      message.value = "";
    };

    // Handle drag events
    const handleDragOver = () => {
      isDragging.value = true;
    };

    const handleDragLeave = (e) => {
      // Only set isDragging to false if we're leaving the drop zone
      if (!e.relatedTarget || !e.currentTarget.contains(e.relatedTarget)) {
        isDragging.value = false;
      }
    };

    const handleDrop = (e) => {
      isDragging.value = false;
      const file = e.dataTransfer.files[0];
      if (file) {
        validateAndSetFile(file);
      }
    };

    // Handle file selection
    const handleFileSelect = (e) => {
      const file = e.target.files[0];
      if (file) {
        validateAndSetFile(file);
      }
    };

    // Validate and set file
    const validateAndSetFile = async (file) => {
      //   clearAlert();
      fileBase64.value = null;

      // Validate file size
      if (file.size > MAX_FILE_SIZE) {
        isError.value = true;
        message.value = "File size exceeds 2MB limit";
        return;
      }

      // Validate file type
      if (!file.name.toLowerCase().endsWith(".csv")) {
        isError.value = true;
        message.value = "Please upload a CSV file";
        return;
      }

      try {
        fileBase64.value = await convertToBase64(file);
        selectedFile.value = file;
      } catch (error) {
        console.error("Error converting file to base64:", error);
        isError.value = true;
        message.value = "Error processing file. Please try again.";
      }
    };

    // Remove file
    const removeFile = () => {
      selectedFile.value = null;
      fileBase64.value = null;
      if (fileInput.value) {
        fileInput.value.value = "";
      }
    };

    // Format file size
    const formatFileSize = (bytes) => {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const sizes = ["Bytes", "KB", "MB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
    };

    // Handle file upload
    const handleUpload = async () => {
      if (!selectedFile.value || !fileBase64.value || isUploading.value) return;

      isUploading.value = true;

      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}${e2payApis.REQUEST_BULK_DISBURSEMENT}`,
          {
            file: fileBase64.value,
          }
        );

        isSuccess.value = true;
        message.value =
          "Disbursement will be proceed shortly, please kindly wait...";
        removeFile();
        setTimeout(() => {
          router.push("/manual-disbursement");
        }, 3000);
      } catch (error) {
        isError.value = true;
        message.value = "Failed to request disbursement. Please try again.";
      } finally {
        isUploading.value = false;
      }
    };

    // Download template
    const downloadTemplate = async (type) => {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}${
            type === "bulk"
              ? e2payApis.REPORT_DOWNLOAD_TEMPLATE_BULK_DISBURSEMENT
              : e2payApis.REPORT_DOWNLOAD_TEMPLATE_BANK
          }`,
          {
            responseType: "blob",
          }
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${
            type === "bulk"
              ? "bulk-disbursement-template"
              : "beneficiary-bank-code"
          }.csv`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Template download failed:", error);
        isError.value = true;
        message.value = "Failed to download template. Please try again.";
      }
    };

    // Cleanup function
    const cleanup = () => {
      if (selectedFile.value) {
        removeFile();
      }
    };

    // Lifecycle hooks
    onMounted(() => {
      window.addEventListener("beforeunload", cleanup);
    });

    onBeforeUnmount(() => {
      cleanup();
      window.removeEventListener("beforeunload", cleanup);
    });

    return {
      // State
      isDragging,
      selectedFile,
      uploadProgress,
      fileInput,
      isUploading,
      isError,
      isSuccess,
      message,

      // Methods
      handleDragOver,
      handleDragLeave,
      handleDrop,
      handleFileSelect,
      handleUpload,
      removeFile,
      formatFileSize,
      downloadTemplate,
      clearAlert,

      // Route
      currentRouteName: router.currentRoute.value.name,
    };
  },
};
</script>

<style scoped>
.main-content-panel {
  display: flex;
  width: 100%;
}

.main-content {
  flex-grow: 1;
  padding: 20px;
}

.template-text {
  font-size: 28px;
}

.template-text-2 {
  font-size: 16px;
  margin-bottom: 30px;
}

.template-link {
  color: #0d6efd;
  cursor: pointer;
  text-decoration: none;
}

.template-link:hover {
  text-decoration: underline;
}

.upload-container {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f8f9fa;
  transition: all 0.3s ease;
}

.drag-over {
  background-color: #e9ecef;
  border-color: #0d6efd !important;
}

.selected-file {
  animation: fadeIn 0.3s ease;
}

.progress {
  height: 20px;
}

.progress-bar {
  height: 20px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .template-text,
  .template-text-2 {
    font-size: 20px;
  }

  .upload-container {
    min-height: 250px;
  }
}
</style>
