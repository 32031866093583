<template>
  <v-dialog persistent v-model="dialog" height="auto">
    <div class="card card-frame">
      <div v-if="loading" style="height: 182px">
        <div
          class="text-center d-flex align-items-center justify-content-center h-100"
        >
          <img
            width="10"
            class="img-fluid"
            :src="imageLoading"
            style="width: 80px; height: 50px"
          />
        </div>
      </div>
      <div v-else>
        <div class="card-header">
          <span class="text-h5">Detail Manual Disbursement</span>
        </div>
        <div class="card-body">
          <EasyDataTable
            show-index
            alternating
            :headers="headers"
            :items="data"
            :loading="isLoadingList"
            hide-footer
          >
            <template #item-disburse_status_desc="item">
              <span
                v-if="item.disburse_status_desc == 'ON PROCESS'"
                class="badge bg-info text-white text-bold"
                >{{ item.disburse_status_desc }}</span
              >
              <span
                v-if="item.disburse_status_desc == 'ALL SUCCESS'"
                class="badge bg-success text-white text-bold"
                >{{ item.disburse_status_desc }}</span
              >
              <span
                v-if="item.disburse_status_desc == 'SUCCESS'"
                class="badge bg-success text-white text-bold"
                >{{ item.disburse_status_desc }}</span
              >
              <span
                v-if="item.disburse_status_desc == 'ALL FAILED'"
                class="badge bg-error text-white text-bold"
                >{{ item.disburse_status_desc }}</span
              >
              <span
                v-if="item.disburse_status_desc == 'FAILED'"
                class="badge bg-error text-white text-bold"
                >{{ item.disburse_status_desc }}</span
              >
              <span
                v-if="item.disburse_status_desc == 'PARTIAL SUCCESS'"
                class="badge bg-warning text-white text-bold"
                >{{ item.disburse_status_desc }}</span
              >
              <span
                v-if="item.disburse_status_desc == 'PARTIAL FAILED'"
                class="badge bg-warning text-white text-bold"
                >{{ item.disburse_status_desc }}</span
              >
            </template>
          </EasyDataTable>
          <div class="row mt-2 text-center">
            <div class="col-12 col-md-12">
              <button
                @click="this.$emit('closeDialog')"
                class="btn btn-block btn-info mb-0 mr-0"
                :disabled="loading"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalManualDisbursement",
  props: {
    openDialog: Boolean,
    data: Array,
    loading: Boolean,
  },
  data: () => ({
    imageLoading: require("@/assets/img/cube_loading.gif"),
    dialog: false,
    headers: [
      { text: "Transaction Ref No", value: "transaction_ref_no" },
      { text: "Merchant", value: "partner_name" },
      { text: "Status", value: "disburse_status_desc" },
      { text: "Bank Name", value: "bank_desc" },
      { text: "Benry Account No.", value: "benry_account_number" },
      { text: "Benry Account Name", value: "benry_account_name" },
      { text: "Disbursed At", value: "disbursed_at" },
      { text: "Amount (IDR)", value: "gross_amount" },
      { text: "Fee Amount (IDR)", value: "fee_amount" },
      { text: "Total Amount (IDR)", value: "total_amount" },
      { text: "Requestor Remarks", value: "requestor_remarks" },
      { text: "Disbursement Remarks", value: "disburse_remarks" },
    ],
  }),
  emits: ["closeDialog"],
  watch: {
    openDialog: function (newVal) {
      this.dialog = newVal;
    },
    data: function () {},
  },
};
</script>
