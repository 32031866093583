<template>
  <div class="row">
    <div class="col-sm-12 mt-sm-0 mt-4">
      <div class="card">
        <div class="col">
          <div class="card-header bg-gray-200">
            Add Web Users
            <button @click="doViewFormUsers">
              <i :class="partnerInfoIcon"></i>
            </button>
          </div>
          <div class="card-body bg-gray-100" v-show="infocollapsed">
            <fieldset :disabled="loading">
              <div class="mb-3">
                <label for="form_p_name" class="form-label">User email</label>
                <input
                  type="text"
                  class="form-control"
                  id="form_p_name"
                  v-model="dataJsonInfo.form_p_email"
                  placeholder="User email"
                />
                <span v-if="v$.dataJsonInfo.form_p_email.$error">{{
                  v$.dataJsonInfo.form_p_email.$errors[0].$message
                }}</span>
              </div>
              <div class="mb-3">
                <label for="form_p_address" class="form-label">Password</label>
                <input
                  type="password"
                  class="form-control"
                  id="form_p_address"
                  v-model="dataJsonInfo.form_p_password"
                  placeholder="Password"
                />
                <span v-if="v$.dataJsonInfo.form_p_password.$error">{{
                  v$.dataJsonInfo.form_p_password.$errors[0].$message
                }}</span>
              </div>
              <div class="mb-3">
                <label for="form_p_email" class="form-label">Fullname</label>
                <input
                  type="email"
                  class="form-control"
                  id="form_p_email"
                  v-model="dataJsonInfo.form_p_name"
                  placeholder="name@example.com"
                />
                <span v-if="v$.dataJsonInfo.form_p_name.$error">{{
                  v$.dataJsonInfo.form_p_name.$errors[0].$message
                }}</span>
              </div>
              <div class="mb-3">
                <label for="form_p_phone" class="form-label">Telephone</label>
                <input
                  type="text"
                  class="form-control"
                  id="form_p_phone"
                  v-model="dataJsonInfo.form_p_phone"
                  placeholder="081234567890"
                />
                <span v-if="v$.dataJsonInfo.form_p_phone.$error">{{
                  v$.dataJsonInfo.form_p_phone.$errors[0].$message
                }}</span>
              </div>
              <div class="mb-3">
                <label for="form_p_channel" class="form-label"
                  >Link to Partners</label
                >
                <VueMultiselect
                  :multiple="true"
                  v-model="dataJsonInfo.form_p_partner"
                  :options="partnerOptions"
                  :taggable="false"
                  label="name"
                  track-by="name"
                  @tag="addTag"
                >
                </VueMultiselect>
                <span v-if="v$.dataJsonInfo.form_p_partner.$error">{{
                  v$.dataJsonInfo.form_p_partner.$errors[0].$message
                }}</span>
              </div>
              <div
                class="form-group d-flex align-items-center justify-content-between"
              >
                <span class="text-sm text-bold">Activate user?</span>
                <div class="form-check form-switch ms-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckDefault30"
                    checked=""
                    v-model="dataJsonInfo.form_p_active"
                  />
                </div>
              </div>
            </fieldset>
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <div :class="classAlertAdd" role="alert" v-if="message.addusers">
              {{ message.addusers }}
            </div>
            <div class="mb-3">
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              <button
                @click="doSubmitAddWebUsers"
                class="btn btn-success mr-2"
                :disabled="loading"
                type="button"
              >
                Submit
              </button>
              <button
                @click="doClearInfo"
                class="btn btn-info"
                :disabled="loading"
                type="button"
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardService from "@/services/dashboard.service";
import useValidate from "@vuelidate/core";
import { email, required, minLength, maxLength } from "@vuelidate/validators";
export default {
  name: "AddWebUser",
  props: {},
  methods: {
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.partnerOptions.push(tag);
    },
    getPartnerList() {
      this.loading = true;
      DashboardService.getPartnerList(this.dataJsonInfo).then((response) => {
        if (response.data.code == 1) {
          this.partnerOptions = response.data.data;
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    doViewFormUsers() {
      this.infocollapsed = !this.infocollapsed;
      if (this.infocollapsed) {
        this.partnerInfoIcon = "fa-solid fa-chevron-up";
      } else {
        this.partnerInfoIcon = "fa-solid fa-chevron-down";
      }
    },
    doClearInfo() {
      this.dataJsonInfo.form_p_name = "";
      this.dataJsonInfo.form_p_password = "";
      this.dataJsonInfo.form_p_email = "";
      this.dataJsonInfo.form_p_phone = "";
      this.dataJsonInfo.form_p_role = "";
      this.dataJsonInfo.form_p_active = "";
    },
    doSubmitAddWebUsers() {
      this.v$.dataJsonInfo.$validate();
      if (!this.v$.dataJsonInfo.$error) {
        this.loading = true;
        DashboardService.postAddWebUser(this.dataJsonInfo).then((response) => {
          this.message.addusers = response.message;
          if (response.code == 1) {
            this.classAlertAdd = "alert alert-success";
          } else {
            this.classAlertAdd = "alert alert-danger";
          }
          this.loading = false;
        });
      } else {
        this.loading = false;
        this.message.addusers = this.v$.dataJsonInfo.$errors[0].message;
      }
    },
  },
  data: () => ({
    v$: useValidate(),
    loading: false,
    message: {
      addusers: "",
    },
    form: "",
    control: "",
    infocollapsed: false,
    partnerInfoIcon: "fa-solid fa-chevron-down",
    classAlertAdd: "alert alert-primary",
    select2settingP: {
      dropdownAutoWidth: false,
      multiple: true,
      width: "100%",
    },
    partnerOptions: [
      { id: 1, text: "Test" },
      { id: 2, text: "Test2" },
    ],
    dataJsonInfo: {
      form_p_name: "",
      form_p_password: "",
      form_p_email: "",
      form_p_phone: "",
      form_p_partner: "",
      form_p_active: false,
    },
  }),
  validations() {
    return {
      dataJsonInfo: {
        form_p_name: {
          required,
          minLength: minLength(5),
          maxLength: maxLength(100),
        },
        form_p_password: { required, minLength: minLength(5) },
        form_p_email: { required, email },
        form_p_phone: { required },
        form_p_partner: "",
        form_p_active: false,
      },
    };
  },
  beforeMount() {
    this.getPartnerList();
  },
};
</script>

<style scoped></style>
