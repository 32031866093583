<template>
  <main class="main-content">
    <div class="container pl-2 pr-2">
      <div class="alert alert-danger" role="alert" v-if="isNotFound">
        Transaction not found
      </div>
      <div
        class="spinner-border"
        style="width: 3rem; height: 3rem"
        role="status"
        v-if="loading"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="center" v-else>
        <div class="card mt-4">
          <div class="row mb-4">
            <div class="col-12 text-center">
              <p class="text-title"><strong>Waiting For Payment</strong></p>
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col-lg-3 col-sm-12 col-12 text-center">
              <p for="exampleInputEmail1" class="form-control-label text-label">
                Payment Method
              </p>
              <img
                :src="getWalletImages"
                class="img-fluid float-center"
                :class="getWalletClassName"
              />
            </div>
            <div class="col-lg-3 col-sm-12 col-12 text-center">
              <div class="form-group">
                <label
                  for="exampleInputEmail1"
                  class="form-control-label text-label"
                  >Payment Reference No.</label
                >
                <input
                  type="text"
                  class="form-control-plaintext text-center"
                  readonly
                  :value="form.transaction_ref_no"
                />
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-12 col-12 text-center">
              <div class="form-group">
                <label
                  for="exampleInputEmail1"
                  class="form-control-label text-label"
                  >Phone Number</label
                >
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control form-control-plaintext text-center text-value"
                    readonly
                    :value="form.phone_number"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-12 col-12 text-center">
              <div class="form-group">
                <label
                  for="exampleInputEmail1"
                  class="form-control-label text-label"
                  >Amount to be paid</label
                >
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control form-control-plaintext text-center text-value"
                    readonly
                    :value="formattingNumber(form.amount)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12 text-center">
              <p class="text-label">
                Purchase/payment will <strong>automatically</strong> be
                cancelled if fund transfer is not received before
                <strong>{{ getExpDate }}</strong> at
                <strong>{{ getExpTime }}</strong>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="card-body">
                <div class="card mb-3 bg-gray">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12">
                        <p class="text-title"><strong>How to Pay</strong></p>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-12">
                        <div class="content-pay text-label">
                          <ol>
                            <li>
                              Wait for <strong>OVO</strong> push notification on
                              your phone (please make sure that you have
                              <strong>OVO apps installed</strong> and has been
                              logged in with
                              <strong>registered phone number</strong>)
                            </li>
                            <li>
                              Open <strong>OVO</strong> push notification and
                              input your
                              <strong
                                >OVO Security Code/OVO biometrics
                                verification</strong
                              >
                            </li>
                            <li>
                              Click <strong>"Pay / Bayar"</strong> Button to
                              proceed your payment (please make sure that your
                              <strong>OVO balance is sufficient</strong>)
                            </li>
                            <li>
                              <strong>OVO</strong> apps will give payment
                              notification whether your payment is
                              <strong>success</strong> or
                              <strong>failed</strong>
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-outline-primary"
                @click="goToMerchant"
              >
                Back to merchant
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import RequestService from "@/services/request.service";
import e2payApis from "@/services/e2pay-apis";
import { formatNumber } from "@/services/number-format";
import moment from "moment";

export default {
  name: "PaymentWalletPage",
  created() {
    document.title = "Checkout Page";
    const link = document.createElement("link");
    link.rel = "icon";
    link.href = "/favicon-co.ico"; // Update with the actual path to your custom favicon
    document.head.appendChild(link);
  },
  mounted() {
    if (this.$route.query.transId == undefined) {
      this.$router.go(-1);
    } else {
      this.fetchData();
      this.repeater = setInterval(() => {
        this.fetchData();
        if (this.status == 1) {
          this.$router.push(
            "/payment-success?isSuccess=1&redirectUrl=" + this.responseUrl
          );
        } else if (this.isExpired) {
          this.$router.push(
            "/payment-failed?status=2&redirectUrl=" + this.responseUrl
          );
        } else if (this.status > 1) {
          this.$router.push(
            "/payment-success?isSuccess=0&redirectUrl=" + this.responseUrl
          );
        }
      }, 10000);
    }
  },
  beforeUnmount() {
    clearInterval(this.repeater);
  },
  components: {},
  computed: {
    getRemainingTime: function () {
      let time = this.remainingTime / 60;
      let minutes = parseInt(time);
      let secondes = Math.round((time - minutes) * 60);
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (secondes < 10) {
        secondes = "0" + secondes;
      }
      return minutes + ":" + secondes;
    },
    getExpDate: function () {
      return moment(this.form.wallet_expiry_timestamp).format("DD MMMM YYYY");
    },
    getExpTime: function () {
      return moment(this.form.wallet_expiry_timestamp).format("HH:mm:ss");
    },
    getWalletImages: function () {
      let walletName = this.form.wallet_type.toLowerCase().trim();
      return walletName == null || walletName == ""
        ? ""
        : this.walletNames[walletName]["src"];
    },
    getWalletClassName: function () {
      let walletName = this.form.wallet_type.toLowerCase();
      return walletName == null || walletName == ""
        ? ""
        : this.walletNames[walletName]["className"];
    },
  },
  methods: {
    formattingNumber(val) {
      return "Rp." + formatNumber(val, 0) + ",-";
    },
    fetchData() {
      this.loading = true;
      RequestService.postRequest(
        e2payApis.GET_WALLET_DETAIL + this.$route.query.transId,
        this.searchParam,
        false
      ).then((response) => {
        let resp = response.data.data;
        if (resp && resp.length > 0) {
          this.form.phone_number = resp[0].phone_number;
          this.form.transaction_ref_no = resp[0].transaction_ref_no;
          this.form.wallet_type = resp[0].wallet_type;
          this.form.amount = resp[0].amount;
          this.form.wallet_expiry_timestamp = resp[0].wallet_expiry_timestamp;
          let dateExp = new Date(resp[0].wallet_expiry_timestamp);
          let dateNow = new Date(resp[0].serverTime.date);
          this.isExpired = dateNow > dateExp;
          var diff = Math.abs(dateExp - dateNow);
          this.remainingTime = diff / 1000;
          this.responseUrl = resp[0].response_url;
          this.status = resp[0].paid_status;
        } else {
          this.isNotFound = true;
        }
        this.loading = false;
        // if (this.status == 1) {
        //   this.$router.push(
        //     "/payment-success?isSuccess=1&status=2&redirectUrl=" +
        //       this.responseUrl
        //   );
        // } else if (this.isExpired) {
        //   this.$router.push(
        //     "/payment-failed?status=2&redirectUrl=" + this.responseUrl
        //   );
        // } else if (this.remainingTime <= 0) {
        //   this.$router.push(
        //     "/payment-success?isSuccess=0&status=1&redirectUrl=" +
        //       this.responseUrl
        //   );
        // }
      });
    },
    goToMerchant() {
      this.$router.push("/payment-redirect?redirectUrl=" + this.responseUrl);
    },
  },
  data: () => ({
    walletNames: {
      ovo: { src: "/assets/img/wallets/ovo.png", className: "default-wallet" },
    },
    loading: false,
    isNotFound: false,
    isExpired: false,
    repeater: null,
    status: false,
    timer: null,
    remainingTime: 0,
    responseUrl: "",
    form: {
      wallet_type: "",
      transaction_ref_no: "",
      phone_number: "",
      wallet_expiry_timestamp: "",
      amount: 0,
    },
  }),
};
</script>

<style scoped>
.text-title {
  font-size: 2.25rem;
  line-height: 1.3;
  font-weight: 500;
  color: #272985;
}
.text-label {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  margin-bottom: 0;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.4rem;
  color: #344767;
}
.text-value {
  font-size: 2rem;
  font-weight: 900;
  line-height: 1.4rem;
  color: #344767;
}
.content-pay {
  padding: 1.5rem;
}

.bg-gray {
  background: rgba(0, 0, 0, 0.05);
}
.default-wallet {
  width: 75px;
}
</style>
