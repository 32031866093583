<template>
  <div class="card card-frame">
    <div class="card-header">
      <h6 class="font-weight-bolder mb-0">
        Simulator Payout Page 2: Disbursement Confirmation
      </h6>
    </div>
    <div class="card-body">
      <div class="row" v-if="isError">
        <div class="col">
          <div
            class="alert alert-danger alert-dismissible fade show"
            role="alert"
          >
            <span class="alert-text text-white"
              ><strong>Error!</strong> {{ errorMsg }}</span
            >
            <button
              class="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>
      <form>
        <div class="row">
          <div class="col-12">
            <h6 class="">Payment Detail</h6>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-6">
            <div class="mb-3 row">
              <label for="staticEmail" class="col-4 col-form-label"
                >Bank Name</label
              >
              <div class="col-7">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext"
                  :value="form.PaymentId.product_name"
                />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3 row">
              <label for="staticEmail" class="col-4 col-form-label"
                >Bank Account No</label
              >
              <div class="col-7">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext"
                  :value="form.BankAccountNo"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="mb-3 row">
              <label for="staticEmail" class="col-4 col-form-label"
                >Customer Phone</label
              >
              <div class="col-7">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext"
                  :value="form.Remarks"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <h6 class="">Transaction Detail</h6>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-6">
            <div class="mb-3 row">
              <label for="staticEmail" class="col-4 col-form-label"
                >Disbursement Amount</label
              >
              <div class="col-7">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext"
                  :value="formattingNumber(form.Amount)"
                />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3 row">
              <label for="staticEmail" class="col-4 col-form-label"
                >Disbursement Fee</label
              >
              <div class="col-7">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext"
                  :value="formattingNumber(form.AdminFee)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3 row">
              <label for="staticEmail" class="col-4 col-form-label"
                >Total</label
              >
              <div class="col-7">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext"
                  :value="getTotal"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="row mt-2">
        <div class="col-12 col-md-12">
          <button
            @click="$router.go(-1)"
            class="btn btn-danger mr-2"
            :disabled="loading"
          >
            Cancel
          </button>
          <button
            @click="next()"
            class="btn btn-success mr-2"
            :disabled="loading"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import useValidate from "@vuelidate/core";
import RequestService from "@/services/request.service";
import e2payApis from "@/services/e2pay-apis";
import { formatNumber } from "@/services/number-format";
import useSimulatorFormStore from "@/store/simulator.form";

export default {
  name: "SimulatorPayoutFormPage2",
  mounted() {
    if (this.simulatorForm.form == null) {
      this.$router.go(-1);
    } else {
      this.form = this.simulatorForm.form;
    }
  },
  methods: {
    formattingNumber(val) {
      return "Rp." + formatNumber(val, 0) + ",-";
    },
    next() {
      RequestService.postRequest(e2payApis.POST_SIMULATOR_PAYOUT, {
        MerchantCode: this.form.MerchantCode.code,
        PaymentId: this.form.PaymentId.payment_id,
        CustomerId: this.form.CustomerId,
        amount: this.form.Amount,
      }).then((response) => {
        if (response.data != null) {
          this.loading = false;
          this.form.totalDisbursement = response.data;
          this.simulatorForm.setForm(this.form);
          this.$router.push({ name: "Simulator Payout Page 3" });
        } else {
          this.loading = false;
          this.isError = true;
          this.errorMsg = "Admin Fee Not Found";
        }
      });
    },
  },
  data: () => ({
    v$: useValidate(),
    loading: false,
    registerMessage: "",
    simulatorForm: useSimulatorFormStore(),
    isError: false,
    errorMsg: "",
    status: "",
    form: {
      totalDisbursement: 0,
      PaymentId: {
        product_name: "",
      },
    },
  }),
  computed: {
    getTotal: function () {
      return (
        "Rp." + formatNumber(this.form.Amount + this.form.AdminFee, 0) + ",-"
      );
    },
  },
  watch: {
    "form.MerchantCode": function (newVal) {
      RequestService.postRequest(e2payApis.GET_LINKED_CHANNEL, {
        type: "payin",
        partnerId: newVal.code,
      }).then((response) => {
        if (response.data.code == 1) {
          this.refChannels = response.data.data;
          this.loading = false;
        } else {
          this.loading = false;
          this.isError = true;
          this.errorMsg = response.data.message;
        }
      });
    },
  },
};
</script>
