<template>
  <main class="main-content">
    <NavigationTop
      :pages="currentRouteName"
      :subpages="currentRouteName"
    ></NavigationTop>
    <div class="container-fluid py-4">
      <div class="row">
        <div class="col-12">
          <div class="card mb-4">
            <div class="card-header pb-0">
              <h6 class="mb-0">{{ currentRouteName }} Form</h6>
            </div>

            <div class="card-body">
              <div class="row" v-if="isError && !isSuccess && message">
                <div class="col">
                  <div
                    class="alert alert-danger alert-dismissible fade show"
                    role="alert"
                  >
                    <span class="alert-text text-white">
                      <strong>Error!</strong>
                      <br />
                      {{ message }}
                    </span>
                    <button
                      class="btn-close"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="row" v-if="!isError && isSuccess && message">
                <div class="col">
                  <div
                    class="alert alert-success alert-dismissible fade show"
                    role="alert"
                  >
                    <span class="alert-text text-white">
                      <strong>Success!</strong>
                      <br />
                      {{ message }}
                    </span>
                    <button
                      class="btn-close"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
              </div>
              <form>
                <div class="form-group">
                  <label class="form-control-label">Merchant</label>
                  <VueMultiselect
                    :multiple="false"
                    v-model="request.MerchantCode"
                    :options="merchantOptions"
                    label="partner_name"
                    track-by="partner_code"
                    placeholder="Pick Merchant"
                    :disabled="loading"
                  >
                  </VueMultiselect>
                  <span
                    class="invalid-feedback"
                    v-if="v$.request.MerchantCode.$error"
                    >{{ v$.request.MerchantCode.$errors[0].$message }}</span
                  >
                </div>
                <div class="form-group">
                  <label class="form-control-label">Amount</label>
                  <v-text-field
                    v-model="formattedAmount"
                    label=""
                    style="background-color: transparent"
                  ></v-text-field>
                </div>
              </form>
              <div class="row mt-2">
                <div class="col-12 col-md-12">
                  <button
                    @click="submit()"
                    class="btn btn-success d-flex align-items-center"
                    :disabled="isSubmitting"
                  >
                    <span
                      v-show="isSubmitting"
                      class="spinner-border spinner-border-sm mr-2"
                    ></span>
                    {{ isSubmitting ? "Submitting..." : "Submit" }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import NavigationTop from "@/components/NavigationTop";
import DashboardService from "@/services/dashboard.service";
import RequestService from "@/services/request.service";
import e2payApis from "@/services/e2pay-apis";
import { formatNumber, getFormattedNumber } from "@/services/number-format";

export default {
  name: "DeductBalanceFormPage",
  components: {
    NavigationTop,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    formattedAmount: {
      get() {
        return formatNumber(this.request.Amount, 0);
      },
      set(newValue) {
        this.request.Amount = getFormattedNumber(newValue);
      },
    },
  },
  methods: {
    getAllMerchants() {
      this.loading = true;
      DashboardService.getAllMerchants().then((response) => {
        if (response.data.code == 1) {
          this.merchantOptions = response.data.data;
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    submit() {
      this.v$.request.$validate();
      if (!this.v$.request.$error) {
        const payload = {
          MerchantCode: this.request.MerchantCode.partner_code || "",
          Amount: this.request.Amount || 0,
        };
        this.isSubmitting = true;
        RequestService.postRequest(e2payApis.DEDUCT_BALANCE_USDT, payload)
          .then((response) => {
            if (response.data.code == 1) {
              this.isSuccess = true;
              this.isError = false;
              this.message = response.data.data.message;
              this.request = {
                MerchantCode: "",
                Amount: 0,
              };
              setTimeout(() => {
                this.$router.push("/payout-transaksi");
                this.isSuccess = false;
                this.isError = false;
                this.message = "";
              }, 2000);
            } else {
              this.isSuccess = false;
              this.isError = true;
              this.message = response.data.message;
              setTimeout(() => {
                this.isSuccess = false;
                this.isError = false;
                this.message = "";
              }, 2000);
            }
          })
          .finally(() => (this.isSubmitting = false));
      }
    },
  },
  data: () => ({
    v$: useValidate(),
    loading: false,
    isSubmitting: false,
    isSuccess: false,
    isError: false,
    message: "",
    imageIcon: require("@/assets/img/logo-paybender.png"),
    merchantOptions: [],
    request: {
      MerchantCode: "",
      Amount: 0,
    },
  }),
  beforeMount() {
    this.getAllMerchants();
  },
  validations() {
    return {
      request: {
        MerchantCode: { required },
        Amount: { required },
      },
    };
  },
};
</script>

<style scoped></style>
