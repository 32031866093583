<template>
  <span
    v-if="loading"
    class="spinner-border spinner-border-sm text-info"
  ></span>
  <div v-else>
    <div class="card card-frame">
      <div class="card-header">
        <h6 class="font-weight-bolder mb-0">Callback Request Form</h6>
      </div>
      <div class="card-body">
        <div class="row" v-if="isError">
          <div class="col">
            <div
              class="alert alert-danger alert-dismissible fade show"
              role="alert"
            >
              <span class="alert-text text-white"
                ><strong>Error!</strong> {{ errorMsg }}</span
              >
              <button
                class="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>
        <form>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label
                  class="form-control-label"
                  :class="{ 'is-invalid': v$.form.partnerCode.$error }"
                  >NST Merchant</label
                >
                <VueMultiselect
                  :multiple="false"
                  :class="{ 'is-invalid': v$.form.partnerCode.$error }"
                  v-model="form.partnerCode"
                  :options="refNstMerchants"
                  :disabled="isDisabledNst"
                  label="name"
                  track-by="code"
                  placeholder="Pick NST Merchant"
                >
                </VueMultiselect>
                <span
                  class="invalid-feedback"
                  v-if="v$.form.partnerCode.$error"
                  >{{ v$.form.partnerCode.$errors[0].$message }}</span
                >
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label">Merchant Ref No</label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': v$.form.listOfMerchantRefNo.$error }"
                  v-model="form.listOfMerchantRefNo"
                />
                <span
                  class="invalid-feedback"
                  v-if="v$.form.listOfMerchantRefNo.$error"
                  >{{ v$.form.listOfMerchantRefNo.$errors[0].$message }}</span
                >
              </div>
            </div>
          </div>
        </form>
        <div class="row mt-2">
          <div class="col-12 col-md-12">
            <button
              @click="next()"
              class="btn btn-success mr-2"
              :disabled="loading"
            >
              Callback
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card card-frame mt-3" v-if="isShowCallback">
      <div class="card-header">
        <h6 class="font-weight-bolder mb-0">Callback Response</h6>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <v-list-item
              v-for="(callbackResponse, index) in callbackResponses"
              :key="index"
              :title="index + 1 + '. ' + callbackResponse"
            >
            </v-list-item>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import useValidate from "@vuelidate/core";
import RequestService from "@/services/request.service";
import e2payApis from "@/services/e2pay-apis";
import useSimulatorFormStore from "@/store/simulator.form";
import { required } from "@vuelidate/validators";

export default {
  name: "SimulatorCallbackRequestFormPage1",
  mounted() {
    this.fetchData();
    if (this.$route.query.errorMsg) {
      this.isError = true;
      this.errorMsg = this.$route.query.errorMsg;
    }
  },
  methods: {
    fetchData() {
      this.loading = true;
      RequestService.postRequest(e2payApis.GET_LINKED_PARTNER, {}).then(
        (response) => {
          if (response.data.code == 1) {
            this.refNstMerchants = response.data.data;
            this.isError = false;
            this.loading = false;
          } else {
            this.loading = false;
            this.isError = true;
            this.errorMsg = response.data.message;
          }
        }
      );
    },
    next() {
      this.v$.form.$validate();
      if (!this.v$.form.$error) {
        this.loading = true;
        this.isShowCallback = false;
        RequestService.postRequest(
          e2payApis.GET_CALLBACK +
            this.form.listOfMerchantRefNo +
            "/" +
            this.form.partnerCode.mchCode,
          []
        ).then((response) => {
          if (response.data != null) {
            this.loading = false;
            if (response.data.code != "00") {
              this.isError = true;
              this.errorMsg = response.data.error;
            } else {
              this.isError = false;
              this.isShowCallback = true;
              this.callbackResponses = response.data.data;
              // this.simulatorForm.setForm(this.form);
            }
          } else {
            this.loading = false;
            this.isError = true;
            this.errorMsg = "Callback Not Found";
          }
        });
      }
    },
  },
  data: () => ({
    v$: useValidate(),
    simulatorForm: useSimulatorFormStore(),
    loading: true,
    registerMessage: "",
    isError: false,
    errorMsg: "",
    status: "",
    refNstMerchants: [],
    isShowCallback: false,
    callbackResponses: [],
    isDisabledNst: false,
    form: {
      partnerCode: "",
      listOfMerchantRefNo: "",
      Signature: "0r2iur4saIcECrvV0t/8cqO0cYo=",
    },
  }),
  validations() {
    return {
      form: {
        listOfMerchantRefNo: { required },
        partnerCode: { required },
      },
    };
  },
  watch: {},
};
</script>
<style>
.is-invalid .multiselect__tags {
  border-color: #fd5c70 !important;
  padding-right: unset;
  /* background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23fd5c70' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23fd5c70' stroke='none'/%3e%3c/svg%3e);
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 1rem 1rem; */
}
</style>
