<template>
  <DashboardLeftMenu></DashboardLeftMenu>
  <DeductBalanceFormPage></DeductBalanceFormPage>
</template>

<script>
import DashboardLeftMenu from "@/components/DashboardLeftMenu";
import DeductBalanceFormPage from "@/views/DeductBalanceFormPage";
export default {
  name: "RoutePageDeductBalance",
  props: {},
  components: {
    DeductBalanceFormPage,
    DashboardLeftMenu,
  },
};
</script>

<style scoped></style>
