<template>
  <span
    v-if="loading"
    class="spinner-border spinner-border-sm text-info"
  ></span>
  <div class="card card-frame" v-else>
    <div class="card-header">
      <h6 class="font-weight-bolder mb-0">
        Manual Disbursement Confirmation Page
      </h6>
    </div>
    <div class="card-body">
      <div class="row" v-if="isError">
        <div class="col">
          <div
            class="alert alert-danger alert-dismissible fade show"
            role="alert"
          >
            <span class="alert-text text-white"
              ><strong>Error!</strong> {{ errorMsg }}</span
            >
            <button
              class="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>
      <form>
        <div class="row">
          <div class="col-12">
            <h4 class=""><strong>Merchant Detail</strong></h4>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <div class="mb-3 row">
              <label
                for="staticEmail"
                class="col-4 col-form-label font-weight-normal"
                >Bank Name</label
              >
              <div class="col-7">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext font-weight-bold"
                  :value="form.paymentId.product_name"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="mb-3 row">
              <label
                for="staticEmail"
                class="col-4 col-form-label font-weight-normal"
                >Bank Account No./Mobile Number</label
              >
              <div class="col-7">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext font-weight-bold"
                  :value="form.customerId"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="mb-3 row">
              <label
                for="staticEmail"
                class="col-4 col-form-label font-weight-normal"
                >Remarks</label
              >
              <div class="col-7">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext font-weight-bold"
                  :value="form.remarks"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <h4 class=""><strong>Transaction Detail</strong></h4>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <div class="mb-3 row">
              <label
                for="staticEmail"
                class="col-4 col-form-label font-weight-normal"
                >Disbursement Amount</label
              >
              <div class="col-7">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext font-weight-bold"
                  :value="formattingNumber(form.amount)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="mb-3 row">
              <label
                for="staticEmail"
                class="col-4 col-form-label font-weight-normal"
                >Disbursement Fee</label
              >
              <div class="col-7">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext font-weight-bold"
                  :value="formattingNumber(form.adminFee)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="mb-3 row">
              <label
                for="staticEmail"
                class="col-4 col-form-label font-weight-normal"
                >Total</label
              >
              <div class="col-7">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext font-weight-bold"
                  :value="getTotal"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="row mt-2">
        <div class="col-12 col-md-12">
          <button
            @click="$router.go(-1)"
            class="btn btn-danger mr-2"
            :disabled="loading"
          >
            Cancel
          </button>
          <button
            @click="next()"
            class="btn btn-success mr-2"
            :disabled="loading"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import useValidate from "@vuelidate/core";
import RequestService from "@/services/request.service";
import e2payApis from "@/services/e2pay-apis";
import { formatNumber } from "@/services/number-format";
import useSimulatorFormStore from "@/store/simulator.form";

export default {
  name: "SimulatorManualDisbursementFormPage2",
  mounted() {
    if (this.simulatorForm.form == null) {
      this.$router.go(-1);
    } else {
      this.form = this.simulatorForm.form;
    }
  },
  methods: {
    formattingNumber(val) {
      return "Rp." + formatNumber(val, 0) + ",-";
    },
    next() {
      this.loading = true;
      let postParam = {
        IssuerMerchantCode: this.form.issuerMerchantCode.code,
        MerchantCode: this.form.partnerId.mchCode.toString(),
        PaymentId: this.form.paymentId.payment_id.toString(),
        CustomerId: this.form.customerId,
        CustomerName: this.form.CustomerName,
        Amount: this.form.amount,
        IssuerCode: this.form.IssuerCode.code,
        Remarks: this.form.remarks,
        SettingFeeFlag: this.form.SettingFeeFlag,
        ToppayFee: this.form.ToppayFee,
        ToppayFeeModel: this.form.ToppayFeeModel,
        ToppaySFee: this.form.ToppaySFee,
        ToppaySFeeModel: this.form.ToppaySFeeModel,
        ReferralFee: this.form.ReferralFee,
        ReferralFeeModel: this.form.ReferralFeeModel,
        ReferralSFee: this.form.ReferralSFee,
        ReferralSFeeModel: this.form.ReferralSFeeModel,
        SupplierFee: this.form.SupplierFee,
        SupplierFeeModel: this.form.SupplierFeeModel,
        SupplierSFee: this.form.SupplierSFee,
        SupplierSFeeModel: this.form.SupplierSFeeModel,
      };
      RequestService.postRequest(
        e2payApis.POST_MANUAL_DISBURSEMENT,
        postParam
      ).then((response) => {
        if (response.data != null && response.data.error == "") {
          this.loading = false;
          this.form.transId = response.data.data.TransId;
          this.form.payId = response.data.data.PayID;
          this.form.processedAt = response.data.data.ProcessedAt;
          this.form.status = response.data.data.Status;
          this.form.errMsg = response.data.data.ErrDesc;
          this.form.bankName = response.data.data.RecipientBankName;
          this.form.bankAccountName = response.data.data.RecipientAccountName;
          this.form.totalAmount = response.data.data.Amount;
          this.simulatorForm.setForm(this.form);
          this.$router.push({ name: "Manual Disbursement Notification Page" });
        } else {
          this.loading = false;
          this.isError = true;
          this.$router.push({
            name: "Manual Disbursement Page",
            query: { errorMsg: response.data.message },
          });
        }
      });
    },
  },
  data: () => ({
    v$: useValidate(),
    simulatorForm: useSimulatorFormStore(),
    loading: false,
    registerMessage: "",
    isError: false,
    errorMsg: "",
    status: "",
    form: {
      paymentId: {
        product_name: "",
      },
    },
  }),
  computed: {
    getTotal: function () {
      return (
        "Rp." + formatNumber(this.form.amount + this.form.adminFee, 0) + ",-"
      );
    },
  },
  watch: {},
};
</script>
