<template>
  <DashboardLeftMenu></DashboardLeftMenu>
  <main class="main-content">
    <NavigationTop
      :pages="currentRouteName"
      :subpages="currentRouteName"
    ></NavigationTop>
    <div class="container-fluid py-4">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body p-3">
              <ChartFilter
                f_action="payout"
                :f_loading="loading"
                :f_submit="searchSubmit"
                :f_download="true"
                :can_download="true"
                :can_show_chart="true"
                @toggle-expand="toggleExpandListener"
                :expand="expand"
                :f_submitdownload="downloadSubmit"
              ></ChartFilter>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card mb-4">
            <div class="card-header pb-0">
              <h6>Chart By Amount</h6>
            </div>
            <div class="card">
              <div class="card-body p-3">
                <TransactionChart
                  :loading="loading"
                  :chartdata="chartAmount"
                  chartTitle="By Amount"
                >
                </TransactionChart>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card mb-4">
            <div class="card-header pb-0">
              <h6>Chart By Transaction</h6>
            </div>
            <div class="card">
              <div class="card-body p-3">
                <TransactionChart
                  :loading="loading"
                  :chartdata="chartTrx"
                  chartTitle="By Trx"
                >
                </TransactionChart>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card mb-4">
            <div class="card-header pb-0">
              <h6>Chart By Transaction Failed</h6>
            </div>
            <div class="card">
              <div class="card-body p-3">
                <TransactionChart
                  :loading="loading"
                  :chartdata="chartFailed"
                  chartTitle="By Failed Trx"
                >
                </TransactionChart>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card mb-4">
            <div class="card-header pb-0">
              <h6>Chart By Transaction Pending</h6>
            </div>
            <div class="card">
              <div class="card-body p-3">
                <TransactionChart
                  :loading="loading"
                  :chartdata="chartPending"
                  chartTitle="By Pending Trx"
                >
                </TransactionChart>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card mb-4">
            <div class="card-header pb-0">
              <h6>Chart By Transaction Success</h6>
            </div>
            <div class="card">
              <div class="card-body p-3">
                <TransactionChart
                  :loading="loading"
                  :chartdata="chartSuccess"
                  chartTitle="By Success Trx"
                >
                </TransactionChart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import DashboardLeftMenu from "@/components/DashboardLeftMenu";
import NavigationTop from "@/components/NavigationTop";
import RequestService from "@/services/request.service";
import TransactionChart from "@/components/TransactionChart";
import e2payApis from "@/services/e2pay-apis";
import ChartFilter from "@/views/ChartFilter";

export default {
  name: "TransactionChartPage",
  components: {
    DashboardLeftMenu,
    NavigationTop,
    TransactionChart,
    ChartFilter,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  data: () => ({
    loading: false,
    searchParam: {},
    chartAmount: {
      labels: [
        "2023-08-01",
        "2023-08-09",
        "2023-08-10",
        "2023-08-19",
        "2023-08-21",
      ],
      datasets: [
        {
          label: "Payout",
          backgroundColor: "#a3f879",
          borderColor: "#a3f879",
          data: [0, 1, 3, 0, 0],
          tension: 0.1,
        },
      ],
    },
    chartTrx: {
      labels: [
        "2023-08-01",
        "2023-08-09",
        "2023-08-10",
        "2023-08-19",
        "2023-08-21",
      ],
      datasets: [
        {
          label: "Payout",
          backgroundColor: "#a3f879",
          borderColor: "#a3f879",
          data: [0, 1, 3, 0, 0],
          tension: 0.1,
        },
      ],
    },
    chartFailed: {
      labels: [
        "2023-08-01",
        "2023-08-09",
        "2023-08-10",
        "2023-08-19",
        "2023-08-21",
      ],
      datasets: [
        {
          label: "Payout",
          backgroundColor: "#a3f879",
          borderColor: "#a3f879",
          data: [0, 1, 3, 0, 0],
          tension: 0.1,
        },
      ],
    },
    chartPending: {
      labels: [
        "2023-08-01",
        "2023-08-09",
        "2023-08-10",
        "2023-08-19",
        "2023-08-21",
      ],
      datasets: [
        {
          label: "Payout",
          backgroundColor: "#a3f879",
          borderColor: "#a3f879",
          data: [0, 1, 3, 0, 0],
          tension: 0.1,
        },
      ],
    },
    chartSuccess: {
      labels: [
        "2023-08-01",
        "2023-08-09",
        "2023-08-10",
        "2023-08-19",
        "2023-08-21",
      ],
      datasets: [
        {
          label: "Payout",
          backgroundColor: "#a3f879",
          borderColor: "#a3f879",
          data: [0, 1, 3, 0, 0],
          tension: 0.1,
        },
      ],
    },
  }),
  mounted() {
    this.fetchData();
  },
  methods: {
    searchSubmit(dataParam) {
      this.searchParam = dataParam;
      // this.searchParam.serverOptions = this.serverOptions;
      this.fetchData();
    },
    fetchData() {
      this.loading = true;
      this.searchParam.trxType = "payout";
      this.searchParam.serverOptions = this.serverOptions;
      // RequestService.postRequest(e2payApis.GET_TRX, this.searchParam).then(
      //     (response)=>{
      //         this.payouttransactions = response.data.data.data;
      //         this.numberOfPages = response.data.data.dataCount;
      //         this.loading = false;
      //         this.filedownload = response.data.data.downloadLink || '';
      //         // if(!this.filedownload){
      //         //   this.downloadSubmit();
      //         // }
      //     }
      // );
      RequestService.postRequest(
        e2payApis.GET_CHART_PAY,
        this.searchParam
      ).then((response) => {
        this.chartAmount = response.data.data.chartByAmount;
        this.chartTrx = response.data.data.chartByTrx;
        this.chartFailed = response.data.data.chartFailed;
        this.chartPending = response.data.data.chartPending;
        this.chartSuccess = response.data.data.chartSuccess;
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped></style>
