<template>
  <DashboardLeftMenu />
  <main class="main-content">
    <NavigationTop
      :pages="currentRouteName"
      :subpages="currentRouteName"
    ></NavigationTop>
    <router-view :item="item" />
  </main>
</template>

<script>
import DashboardLeftMenu from "@/components/DashboardLeftMenu";
import NavigationTop from "@/components/NavigationTop";

export default {
  name: "ManualDisbursementIndex",
  components: {
    DashboardLeftMenu,
    NavigationTop,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<style scoped></style>
