<!-- eslint-disable no-unused-vars -->
<template>
  <div class="card card-frame">
    <div class="card-header">
      <button
        class="btn btn-icon btn-3 btn-outline-primary mt-2"
        @click="$router.go(-1)"
      >
        <span class="btn-inner--icon"><i class="fa fa-arrow-left"></i></span>
      </button>
      Update Mapping Issuer Form
    </div>
    <div class="card-body">
      <div class="row" v-if="isError && !isSuccess && message">
        <div class="col">
          <div
            class="alert alert-danger alert-dismissible fade show"
            role="alert"
          >
            <span class="alert-text text-white">
              <strong>Error!</strong>
              <br />
              {{ message }}
            </span>
            <button
              class="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>
      <div class="row" v-if="!isError && isSuccess && message">
        <div class="col">
          <div
            class="alert alert-success alert-dismissible fade show"
            role="alert"
          >
            <span class="alert-text text-white">
              <strong>Success!</strong>
              <br />
              {{ message }}
            </span>
            <button
              class="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>
      <form>
        <div class="">
          <div class="col-md-6">
            <div class="form-group">
              <label for="mapping_type_id" class="form-control-label"
                >Mapping Type</label
              >
              <VueMultiselect
                :multiple="false"
                v-model="form.mapping_type_id"
                :options="mappingTypeOptions"
                label="name"
                track-by="id"
                placeholder="Pick Mapping Type"
              >
              </VueMultiselect>
              <span
                class="invalid-feedback"
                v-if="v$.form.mapping_type_id.$error"
                >{{ v$.form.mapping_type_id.$errors[0].$message }}</span
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label">Merchant</label>
              <VueMultiselect
                :multiple="false"
                v-model="form.partner_id"
                :options="merchantOptions"
                label="partner_name"
                track-by="partner_id"
                placeholder="Pick Merchant"
                :disabled="disabledMerchant"
              >
              </VueMultiselect>
              <!-- <span class="invalid-feedback" v-if="v$.form.partner_id.$error">{{
                v$.form.partner_id.$errors[0].$message
              }}</span> -->
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label">Channel Type</label>
              <VueMultiselect
                :multiple="false"
                v-model="form.channel_type"
                :options="channelTypeOptions"
                label="channel_type_name"
                track-by="channel_type_id"
                placeholder="Pick Channel Type"
                :disabled="disabledChannelType"
              >
              </VueMultiselect>
              <!-- <span
                class="invalid-feedback"
                v-if="v$.form.channel_type.$error"
                >{{ v$.form.channel_type.$errors[0].$message }}</span
              > -->
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label">Channel</label>
              <VueMultiselect
                :multiple="true"
                v-model="form.payment_id"
                :options="channelOptions"
                label="product_name"
                track-by="payment_id"
                placeholder="Pick Channel"
                :disabled="disabledChannel"
              >
              </VueMultiselect>
              <!-- <span class="invalid-feedback" v-if="v$.form.payment_id.$error">{{
                v$.form.payment_id.$errors[0].$message
              }}</span> -->
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label">Issuer</label>
              <VueMultiselect
                :multiple="false"
                v-model="form.pg_id"
                :options="issuerOptions"
                label="pg_name"
                track-by="pg_id"
                placeholder="Pick Issuer"
                :disabled="disabledIssuer"
              >
              </VueMultiselect>
              <!-- <span class="invalid-feedback" v-if="v$.form.pg_id.$error">{{
                v$.form.pg_id.$errors[0].$message
              }}</span> -->
            </div>
          </div>
        </div>
      </form>
      <div class="row mt-2">
        <div class="col-12 col-md-12">
          <button
            @click="submit()"
            class="btn btn-success d-flex align-items-center"
            :disabled="isSubmitting"
          >
            <span
              v-show="isSubmitting"
              class="spinner-border spinner-border-sm mr-2"
            ></span>
            {{ isSubmitting ? "Updating..." : "Update" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import DashboardService from "@/services/dashboard.service";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  name: "MerchantChannelIssuerForm",
  components: {},
  mounted() {},
  methods: {
    getAllMerchants() {
      this.loading = true;
      DashboardService.getAllMerchants().then((response) => {
        if (response.data.code == 1) {
          this.merchantOptions = response.data.data;
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    getAllChannelTypes() {
      this.loading = true;
      DashboardService.getAllChannelTypes().then((response) => {
        if (response.data.code == 1) {
          this.channelTypeOptions = response.data.data;
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    getAllChannels(param) {
      this.loading = true;
      DashboardService.getAllChannels(param ?? {}).then((response) => {
        if (response.data.code == 1) {
          this.channelOptions = response.data.data;
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    getAllIssuers() {
      this.loading = true;
      DashboardService.getAllIssuers().then((response) => {
        if (response.data.code == 1) {
          this.issuerOptions = response.data.data;
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    submit() {
      this.v$.form.$validate();
      if (!this.v$.form.$error) {
        const payload = {
          channel_type: this.form.channel_type?.channel_type_id || "-",
          payment_id:
            this.form.payment_id?.length > 0
              ? this.form.payment_id?.map((item) => item.payment_id)
              : [],
          partner_id: this.form.partner_id.partner_id || 0,
          pg_id: this.form.pg_id.pg_id || 0,
        };
        this.isSubmitting = true;
        DashboardService.updateChannelMapping(payload)
          .then((response) => {
            if (response.data.code == 1) {
              this.isSuccess = true;
              this.isError = false;
              this.message = response.data.data.message;
              this.form = {
                mapping_type_id: "",
                partner_id: "",
                payment_id: "",
                pg_id: "",
                channel_type: "",
              };
              setTimeout(() => {
                this.$router.push("/merchant-channel-issuer");
                this.isSuccess = false;
                this.isError = false;
                this.message = "";
              }, 2000);
            } else {
              this.isSuccess = false;
              this.isError = true;
              this.message = response.data.message;
              setTimeout(() => {
                this.isSuccess = false;
                this.isError = false;
                this.message = "";
              }, 2000);
            }
          })
          .finally(() => (this.isSubmitting = false));
      }
    },
  },
  data: () => ({
    v$: useValidate(),
    loading: false,
    isSubmitting: false,
    isSuccess: false,
    isError: false,
    message: "",
    status: "",
    disabledMerchant: true,
    disabledChannelType: true,
    disabledChannel: true,
    disabledIssuer: true,
    mappingTypeOptions: [
      {
        id: 1,
        name: "Update single mapping issuer",
      },
      {
        id: 2,
        name: "Update bulk mapping issuer by channel type",
      },
      {
        id: 3,
        name: "Update bulk mapping issuer by multi-selected channel",
      },
      {
        id: 4,
        name: "Update bulk mapping issuer by merchant and channel type",
      },
      {
        id: 5,
        name: "Update bulk mapping issuer by merchant and multi-selected channel",
      },
    ],
    merchantOptions: [],
    channelTypeOptions: [],
    channelOptions: [],
    issuerOptions: [],
    form: {
      mapping_type_id: "",
      partner_id: "",
      payment_id: "",
      pg_id: "",
      channel_type: "",
    },
  }),
  validations() {
    return {
      form: {
        mapping_type_id: { required },
      },
    };
  },
  watch: {
    "form.mapping_type_id": function (val) {
      this.form.channel_type = "";
      this.form.partner_id = "";
      this.form.payment_id = "";
      this.form.pg_id = "";
      if (val?.id == 1) {
        this.disabledMerchant = false;
        this.disabledChannel = false;
        this.disabledIssuer = false;
        this.disabledChannelType = true;
        this.getAllMerchants();
        this.getAllChannels();
        this.getAllIssuers();
      } else if (val?.id == 2) {
        this.disabledMerchant = true;
        this.disabledChannel = true;
        this.disabledChannelType = false;
        this.disabledIssuer = false;
        this.getAllChannelTypes();
        this.getAllIssuers();
      } else if (val?.id == 3) {
        this.disabledMerchant = true;
        this.disabledChannelType = true;
        this.disabledChannel = false;
        this.disabledIssuer = false;
        this.getAllChannels();
        this.getAllIssuers();
      } else if (val?.id == 4) {
        this.disabledMerchant = false;
        this.disabledChannelType = false;
        this.disabledIssuer = false;
        this.disabledChannel = true;
        this.getAllMerchants();
        this.getAllChannelTypes();
        this.getAllIssuers();
      } else if (val?.id == 5) {
        this.disabledMerchant = false;
        this.disabledChannelType = false;
        this.disabledChannel = false;
        this.disabledIssuer = false;
        this.getAllMerchants();
        this.getAllChannelTypes();
        this.getAllChannels();
        this.getAllIssuers();
      }
    },
    "form.channel_type": function (val) {
      if (val) {
        this.getAllChannels(
          val ? { filter_channel_type: val?.channel_type_id } : {}
        );
      }
    },
  },
};
</script>
